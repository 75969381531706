import Api from "../network/Api";
import { GET_MEDIA_PATH } from "../network/ApiConstants";

const IMAGES_CACHE = "IMAGES_CACHE";
const TWO_WEEKS = 1000 * 60 * 60 * 24 * 14;

async function getMediaWithId(id, imageUrlCallback) {
  const localImage = getImageFromCache(id);
  if (localImage != null) {
    imageUrlCallback(localImage.imageUrl);
  } else {
    getMediaDataWithId(id, (mediaData) => {

      if (!mediaData ||
        !mediaData["media_details"] ||
        !mediaData["media_details"]["sizes"]) {
        imageUrlCallback("")
      }

      let size = "medium";
      if (mediaData["media_details"]["sizes"]["large"]) {
        size = "large";
      }
      const imageUrl =
        mediaData["media_details"]["sizes"][size]["source_url"];
      setImageToCache(id, imageUrl);
      imageUrlCallback(imageUrl);
    });
  }
}

async function getMediaDataWithId(id, mediaCallback) {
  Api.get(
    GET_MEDIA_PATH + id,
    (data) => {
      mediaCallback(data);
    },
    (error) => {
      //handle error
      console.warn("Error: " + error);
      mediaCallback(null);
    }
  );
}

const ImageService = {
  getMediaWithId,
  //   getMediaDataWithId,
};

const currentTime = () => {
  return Date.now();
};

const getImageFromCache = (userId) => {
  const imagesCache = getImagesCache();
  const data = imagesCache.data;

  return data[userId];
};

const getImagesCache = () => {
  let imagesCache = {
    data: {},
    nextCleanup: new Date().getTime() + TWO_WEEKS,
  };

  try {
    const data = localStorage.getItem(IMAGES_CACHE);

    if (data) {
      imagesCache = JSON.parse(data);
    }
  } catch (e) {
    console.error(e.message);
  }

  return imagesCache;
};

const setImageToCache = (userId, imageUrl) => {
  const imagesCache = getImagesCache();
  const data = imagesCache.data;

  const item = {
    id: userId,
    expiry: new Date().getTime() + TWO_WEEKS,
    imageUrl: imageUrl,
  };

  data[userId] = item;

  try {
    localStorage.setItem(IMAGES_CACHE, JSON.stringify(imagesCache));
  } catch (e) {
    cleanUpStorage(data);
  }
};

const cleanUpStorage = (data) => {
  let isDeleted;
  let oldest;
  let oldestKey;

  //if 14 days have been passed, it removes the cache
  for (const key in data) {
    //console.log("key is", key);
    const expiry = data[key].expiry;
    if (expiry && expiry <= currentTime()) {
      delete data[key];
      isDeleted = true;
    }

    //finding the oldest cache in case none of them are expired
    if (!oldest || oldest > expiry) {
      oldest = expiry;
      oldestKey = key;
    }
  }

  //remove the oldest cache if there is no more space in local storage (5 MB)
  if (!isDeleted && oldestKey) {
    delete data[oldestKey];
  }

  localStorage.setItem(
    IMAGES_CACHE,
    JSON.stringify({
      data: data,
      nextCleanup: currentTime() + TWO_WEEKS,
    })
  );
};

export default ImageService;
