import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { GlobalContext } from "../../store/contexts/Contexts";
import { useTracking } from "../../utils/tracking";

import { useParams} from 'react-router-dom';
 
const useStyles = makeStyles((theme) => ({
  iframe: {
    width: "100%",
    height: "calc(100vh - 69px)",
  },
}));

export default function IFramePage({ coachSlug1 }) {
  const classes = useStyles();

  const params = useParams();

  const coachSlug = params.coachSlug;

  const [url, setUrl] = useState("https://meetings.hubspot.com/" + coachSlug);

  const { coachesState } = useContext(GlobalContext);

  //if (coachesState.coaches) //console.log("coachesState.coaches.length",coachesState.coaches.length);

  const {trackPage} = useTracking();
  
  useEffect(() => {
    if (coachesState.coaches && coachesState.coaches.length > 0 ) {
      //console.log("iframe coach hubspot coachSlug:",coachSlug)
      const coachFilt = coachesState.coaches.filter(
        (coach) => coach.slug === coachSlug
      );
      const coach = coachFilt.length > 0 ? coachFilt[0] : null;
      console.log("iframe coach hubspot coach:",coach, coachFilt,coachesState.coaches, coachSlug);

      const hubspot_link = coach["acf"]["hubspot_calender_link"]
        ? coach["acf"]["hubspot_calender_link"]
        : "https://meetings.hubspot.com/" + coach.slug;

      setUrl(hubspot_link);
      trackPage()
      //console.log("iframe coach hubspot url1:",url)
    }
    // eslint-disable-next-line
  }, [coachesState.coaches]);

  return (
    <div>
      <iframe className={classes.iframe} title="Mentor" src={url} />
    </div>
  );
}
