import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { Container, Modal, Box } from "@material-ui/core";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";

import CancelPresentationTwoToneIcon from "@material-ui/icons/CancelPresentationTwoTone";

import Vimeo from "@u-wave/react-vimeo";

import { GlobalContext } from "../../store/contexts/Contexts";
// import { _approaches, _subjects } from "../../data/QuestionsData";

import DetailsMobile from "../../components/details/mobile-version/DetailsMobile";
import DetailsDesktop from "../../components/details/desktop-version/DetailsDesktop";



import {
  sortAndMatchCoachACFs,
  prepareMatches,
} from "../../store/reducers/ACF_prepareCoachFields";

import { useTracking } from "../../utils/tracking";

// const useStyles = makeStyles((theme) => ({
//   root: {},
// }));

export default function CoachDetails() {
  
  
  

  const location = useLocation();

  const { coachesState } = useContext(GlobalContext);
  const [videoOpen, setVideoOpen] = useState(false);
  const [coach, setCoach] = useState(location.state);
  const [preparedCoach, setPreparedCoach] = useState(null);
  const [coachmatches, setCoachmatches] = useState([]);

  const history = useHistory();
  const params = useParams();

  const coachSlug = params.coachSlug;

  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });


  const windowW = window.innerWidth;
  const windowH = window.innerHeight;

  const heightGreaterWidth = windowH > windowW;

  const vimeoWidthPositionMove =
    heightGreaterWidth && coach && coach.acf.vimeo_move_horizontal_percent
      ? parseInt(coach.acf.vimeo_move_horizontal_percent, 10)
      : 0;

  useEffect(() => {
    setSelectedCoachForCoachSlug(coachSlug);
    // eslint-disable-next-line
  }, [coachSlug]);

  useEffect(() => {
    setSelectedCoachForCoachSlug(coachSlug);
    // eslint-disable-next-line
  }, [coachesState]); // on initial loading, no coaches are loaded.

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (coach) {
      const prepCoach = sortAndMatchCoachACFs(coach);
      //console.log("useEffect coach",coach, prepCoach)
      setPreparedCoach(prepCoach);
    }
    // eslint-disable-next-line
  }, [coach]);

  useEffect(() => {
    if (coach) {
      setCoachmatches(prepareMatches(coach.fieldScoresSorted));
    }
    // eslint-disable-next-line
  }, [coach]);

  const {trackPage} = useTracking();
  useEffect(() => {
    if (!coach || coach === undefined) return;
    window.history.replaceState(null, "", "/coaches/" + coach.slug);
    
    trackPage();
    // eslint-disable-next-line
  }, [coach]);

  // for bookmarked coach loading:
  const setSelectedCoachForCoachSlug = (coachSlug) => {
    if (!coachesState.coaches) {
      return;
    }
    if (coachSlug) {
      if (!coach || coach.slug !== coachSlug) {
        let coach = coachesState.coaches.filter((c) => c.slug === coachSlug);

        coach = coach.length > 0 ? coach[0] : null;
        // setting selected coach from coach-slug in url:

        coach && setCoach(coach);
      }
    }
  };

  function changeCoach(forward) {
    const currentIndex = coachesState.coaches.findIndex(
      (item) => item.id === coach.id
    );
    var index = forward ? currentIndex + 1 : currentIndex - 1;
    index = index % coachesState.coaches.length;
    if (index < 0) index = coachesState.coaches.length - 1;

    setCoach(coachesState.coaches[index]);
  }

  const terminAction = () => {
    if (coach.acf.appointmentType && coach.acf.appointmentType === "hubspot"){
      history.push("/hubspot/" + coach.slug);
    }else{
      history.push({
        pathname: "/appointment/" + coach.slug, //"/hubspot/" + coach.slug,
      });
    }
    // 
  };

  return (
    <Container>
      {preparedCoach && (
        <div>
          {isMobileDevice ? (
            <DetailsMobile
              coach={preparedCoach}
              coachmatches={coachmatches}
              changeCoach={changeCoach}
              terminAction={terminAction}
              naviToOverview={() => history.push("/coaches")}
              didClickVideo={() => setVideoOpen(true)}
            />
          ) : (
            <DetailsDesktop
              coach={preparedCoach}
              coachmatches={coachmatches}
              changeCoach={changeCoach}
              terminAction={terminAction}
              naviToOverview={() => history.push("/coaches")}
              didClickVideo={() => setVideoOpen(true)}
            />
          )}

          <Modal
            open={videoOpen}
            onClose={() => setVideoOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              style={{
                position: "absolute",
                top: "50%",
                left: 50 + vimeoWidthPositionMove + "%",
                transform: "translate(-50%, -50%)",
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                //backgroundColor: 'white',
              }}
            >
              <div
                onClick={() => setVideoOpen(false)}
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Vimeo
                  video={preparedCoach.acf.video_vimeo}
                  autoplay={true}
                  height={windowH - 40}
                />
                <CancelPresentationTwoToneIcon
                  fontSize="large"
                  color="primary"
                  style={{
                    position: "absolute",
                    right: "calc(" + vimeoWidthPositionMove + "% + 10px)",
                    top: 10,
                  }}
                />
              </div>
            </Box>
          </Modal>
        </div>
      )}
    </Container>
  );
}
