import React from "react";
import { useMediaQuery } from "react-responsive";

import Grid from "@material-ui/core/Grid";
import Coach from "./Coach";
import { finderData } from "../../data/IntroData";
import { useHistory } from "react-router-dom";
import CoachFinderItem from "../coach-finder/CoachFinderItem";

export default function CoachesList({ repos, onCoachSelect }) {
  const history = useHistory();

  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });

  if (!repos || !repos.length) return <p>No repos, sorry</p>;
  return (
    <Grid
      container
      spacing={isMobileDevice ? 2 : 4}
      align="center"
      alignItems="center"
    >
      {!isMobileDevice && (
        <CoachFinderItem
          key="0"
          card={finderData[0]}
          onClick={() => history.push("/coach-finder")}
        />
      )}
      {repos.map((coach,i) => (
        <Coach
          key={coach.slug}
          _key={coach.slug}
          coach={coach}
          animate={true}
          onCoachSelect={(coach) => onCoachSelect(coach, "normallist-coach", i) }
        />
      ))}
    </Grid>
  );
}
