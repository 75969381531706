import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.0rem",
    fontWeight: 800,
    paddingTop: "1vh",
    paddingBottom: "1vw",
    flexGrow: 1,
  },
  description: {
    fontSize: "0.9rem",
    fontWeight: 400,
    textAlign: "start",
  },
}));

const MTitleAndText = ({ title, text }) => {
  const classes = useStyles();
  
  return (
    <div>
      <Typography className={classes.title}>{title}</Typography>
      <div className={classes.description} dangerouslySetInnerHTML={{__html:text}}  ></div>
    </div>
  );
};

export default MTitleAndText;
