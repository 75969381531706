import React, { useContext, useEffect, useState } from "react";
import Calendar from "react-calendar";

import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useHistory, useParams } from "react-router";
import Skeleton from "@material-ui/lab/Skeleton";
import "../../assets/style/Calendar.css";
import { GlobalContext } from "../../store/contexts/Contexts";
import ImageService from "../../services/ImageService";
import SalonService from "../../services/SalonService";
import {
  DID_GET_INTERVAL_DATES,
  DID_GET_INTERVAL_TIMES,
  DID_GET_EXTERNAL_FREEBUSY,
  IS_LOADING,
  SELECT_DATE,
  SET_ASSISTANT_ID,
} from "../../store/reducers/AppointmentReducer";
import WithLoading from "../../hoc/WithLoading";
import { useTracking } from "../../utils/tracking";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imageBox: {
    width: "100%",
    height: 180,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
  },
  media: {
    //width: 80,
    height: 80,
    display: "flex",
    textAlign: "center",
    borderRadius: 40,
    borderWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
  name: {
    fontSize: "1.4rem",
    fontWeight: 600,
    marginTop: 10,
    display: "flex",
  },
  coachTxt: {
    fontSize: "0.8rem",
    marginBottom: 5,
    
  },
  calendar: {},
}));

export default function CalendarPage() {
  const { coachesState, appointmentState, appointmentDispatch } =
    useContext(GlobalContext);

  const [coach, setCoach] = useState();

  const [imageUrl, setImageUrl] = useState();
  const [media, setMedia] = useState({
    loading: true,
    image: null,
  });

  const { coachSlug } = useParams();

  const classes = useStyles();
  const history = useHistory();

  const _minDate = new Date();
  const _maxDate = () => {
    let currentdate = new Date();
    let maxD = new Date(currentdate.setMonth(currentdate.getMonth() + 2));
    return maxD;
  };

  const [activeStartDate, setActiveStartDate] = useState(new Date());

  //In these two effects we retrieve the coach from coachSlug
  useEffect(() => {
    setSelectedCoachForCoachSlug(coachSlug);

    // eslint-disable-next-line
  }, [coachSlug]);

  
  useEffect(() => {
    setSelectedCoachForCoachSlug(coachSlug);

    // eslint-disable-next-line
  }, [coachesState]);

  //When we retrive coach data, we get all appoitment information needed for calendar
  useEffect(() => {
    if (coach) {
      appointmentDispatch({
        type: SET_ASSISTANT_ID,
        payload: coach.acf.coach_assistent,
      });

      getAvailableDates(new Date());

      const calslug = coach.acf.google_calendar_slug ? coach.acf.google_calendar_slug : coach.slug;
      const calid = coach.acf.google_calendar_calendar_id ? coach.acf.google_calendar_calendar_id : null;

      const calconfig = {type:"google", calslug, calid};
      SalonService.getCoachExternalBusyByMonth(
        calconfig,
        new Date(),
        (coachbusy) => {
          console.log("SalonService.getCoachExternalBusy",coachbusy);
          appointmentDispatch({
            type: DID_GET_EXTERNAL_FREEBUSY,
            payload: coachbusy,
          });
        }

      );
    }
    // eslint-disable-next-line
  }, [coach]);

    const {trackPage} = useTracking();
    useEffect(() => {
      if (coach)
        trackPage();
    }, [coach])


  //After we set up the image url from the coach,
  //We get the media from it
  useEffect(() => {
    imageUrl && getMedia();

    // eslint-disable-next-line
  }, [imageUrl]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  // for bookmarked coach loading:
  const setSelectedCoachForCoachSlug = (coachSlug) => {
    //console.log("coach slug");
    //console.log(coachSlug);

    if (!coachesState.coaches) {
      return;
    }
    if (coachSlug) {
      if (!coach || coach.slug !== coachSlug) {
        let coach = coachesState.coaches.filter((c) => c.slug === coachSlug);

        coach = coach.length > 0 ? coach[0] : null;
        // setting selected coach from coach-slug in url:
        //console.log("coach0");

        if (coach) {
          //console.log("coach1");
          //console.log(coach);
          setCoach(coach);
          setImageUrl(coach["featured_media"]);
        }
      }
    }
  };

  const getMedia = () => {
    setMedia({ loading: true, image: null });
    ImageService.getMediaWithId(imageUrl, (imageUrl) => {
      setMedia({ loading: false, image: imageUrl });
    });
  };

  const getAvailableDates = (date) => {
    const _date = date.toLocaleDateString("en-CA");
    appointmentDispatch({ type: IS_LOADING });
    SalonService.getCoachAvailability(
      coach.acf.coach_assistent,
      _date,
      (coachIntervals) => {
        appointmentDispatch({
          type: DID_GET_INTERVAL_DATES,
          payload: coachIntervals,
        });
      }
    );
  };

  const didSelectDate = (date) => {
    const _date = date.toLocaleDateString("en-CA");
    SalonService.getCoachAvailability(
      coach.acf.coach_assistent,
      _date,
      (coachIntervals) => {
        appointmentDispatch({
          type: SELECT_DATE,
          payload: { coach: coach, date: date },
        });
        appointmentDispatch({
          type: DID_GET_INTERVAL_TIMES,
          payload: coachIntervals,
        });
        
        
        history.push("/time-selection");
      }
    );
    
    
   
  };

  function didChangeMonth({ activeStartDate, value, view }) {
    console.log("****didChangeMonth");
    setActiveStartDate(activeStartDate);
    if (view === "month") {
      getAvailableDates(activeStartDate);

      SalonService.getCoachExternalBusyByMonth(
        coach.slug,
        activeStartDate,
        (coachbusy) => {
          console.log("SalonService.getCoachExternalBusy",coachbusy);
          appointmentDispatch({
            type: DID_GET_EXTERNAL_FREEBUSY,
            payload: coachbusy,
          });
        }

      );
    }

  }

  function tileDisabled({ date, view }) {
    // Disable tiles in month view only
    if (view !== "month") return false;
    var d = new Date(date);
    var day = d.getDate();
    var month = d.getMonth();

    var d21 = new Date();
    d21.setDate(d21.getDate()+21); // not more then 21 days

    if (date > d21){
      return true; // workaround saalon avilibility bug there seems to be shown more dates availaable as configured
                   // e.g. 1.11 : + 14 days should be avaailable but 2.12. and 3.12. are also available
    }
    //console.log(appointmentState.availableDays);
    return !appointmentState.availableDays.find((aDate) => aDate.getDate() === day && aDate.getMonth() === month );
    // if (view === "month") {
    //   // Check if a date React-Calendar wants to check is on the list of disabled dates
    //   return disabledDates.find((dDate) => isSameDay(dDate, date));
    // }
  }

  const MCalendar = () => {
    return (
      <Grid container justify="center" align="center" direction="column">
        <Grid item>
          <Box className={classes.imageBox}>
            {media.loading ? (
              <Skeleton
                animation="wave"
                variant="rect"
                className={classes.media}
              />
            ) : (
              <img
                className={classes.media}
                src={media.image}
                component="img"
                alt=""
              />
            )}
            <Typography className={classes.name}>
              {coach.title.rendered}
            </Typography>
            <Typography className={classes.coachTxt}>Kein passendes Datum dabei? <br/>Einfach einen <a target="_blank" href={"https://share.hsforms.com/1OlF8v2ntS4yQWKFqB96jTQ4v9dl?ihr_wunsch_coach=" + coach.title.rendered}> andere Termin anfragen.</a> </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Calendar
            className={classes.calendar}
            onChange={didSelectDate}
            minDetail="month"
            // value={value
            onActiveStartDateChange={didChangeMonth}
            minDate={_minDate}
            maxDate={_maxDate()}
            activeStartDate={activeStartDate}
            // disabledDate={false}
            tileDisabled={tileDisabled}
          />
        </Grid>
      </Grid>
    );
  };

  const CalendarWithLoading = WithLoading(MCalendar);

  return (
    <Box className={classes.root}>
      <Box maxWidth="600px">
        <CalendarWithLoading isLoading={coach == null || appointmentState.loading === true} />
      </Box>
    </Box>
  );
}
