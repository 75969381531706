import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { isArray } from "util";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: "1.0rem",
    fontWeight: 800,
    color: "black",
    marginBottom: 10,
  },
  card: {
    // backgroundImage: `url(${TestimonialsBg})`,
    // backgroundSize: "cover",
    // backgroundPosition: "center",
  },

  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: 60,
    height: 60,
  },
  headerTitle: {
    fontWeight: 800,
    fontSize: "0.8rem",
  },
  headerSubtitle: {
    fontWeight: 800,
    fontSize: "0.7rem",
    borderBottom: `${"1px solid "} ${theme.palette.primary.main}`,
    paddingBottom: 10,
    color: grey[300],
  },
  description: {
    fontWeight: 500,
    fontSize: "0.9rem",
  },
}));

function SwipableTestimonials( {coachname, testimonials, showNavigation=true} ) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [testimonalsArray, setTestimonalsArray] = React.useState([]);
  const maxSteps = testimonalsArray.length;

  useEffect(() => {
    //console.log("SwipableTestimonials testimonials:", testimonials)
    if (testimonials && isArray(testimonials)) setTestimonalsArray(testimonials);
  }, [testimonials])

  //console.log("SwipableTestimonials testimonalsArray:", testimonalsArray)
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const Testimonial = ({ item }) => {
    return (
      <Card elevation={2} style={{ margin: 20 }}>
        
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              {item.testimonial_autor.substring(0,1).toUpperCase()}
            </Avatar>
          }
          title={item.testimonial_autor}
          //subheader={item.company}
          classes={{
            title: classes.headerTitle,
            subheader: classes.headerSubtitle,
          }}
        />
        <CardContent>
          <Typography className={classes.description}>
            {item.testiomnial_text}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>
        Das sagen Kund*innen über {coachname}
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        marginLeft="-20px"
        marginTop="10px"
      >
        {showNavigation && <IconButton onClick={handleBack} disabled={activeStep === 0}>
          <KeyboardArrowLeft
            fontSize="large"
            color={activeStep === 0 ? "secondary" : "primary"}
          />
        </IconButton>}
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {testimonalsArray.map((item, index) => (
            <div key={item.testimonial_autor} style={{display: "flex", justifyContent:"center"}}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Testimonial item={item} />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
        {showNavigation && <IconButton onClick={handleNext} disabled={activeStep === maxSteps - 1}>
          <KeyboardArrowRight
            fontSize="large"
            color={activeStep === maxSteps - 1 ? "secondary" : "primary"}
          />
        </IconButton>}
      </Box>
      <Box display="flex" justifyContent="center" marginTop="-0px">
        <MobileStepper
          steps={maxSteps}
          position="static"
          variant="dots"
          activeStep={activeStep}
        />
      </Box>
    </div>
  );
}

export default SwipableTestimonials;
