import React from 'react'
import { Link, Box } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  
  link: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: "0.8rem",
    marginTop: 10,
    marginLeft: 15,
    marginBottom: 10,
  },
}))

export default function Footer() {

  const classes = useStyles();

  return (
    
    <Box sx={{ boxShadow: 10 }} style={{
        backgroundColor: "white",
        bottom: 0, 
        display: "flex",
        justifyContent: "center",
        position: "sticky"
      }}><Link 
          underline="none" 
          href="https://www.mentor-app.de"> 
            <Typography className={classes.link}>zur Mentor Seite</Typography></Link></Box>
  )
}
