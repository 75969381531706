import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";
// import { useTranslation } from "react-i18next";

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import IntroCard from "../../components/IntroCard";
import { introData } from "../../data/IntroData";
import { makeStyles, Typography } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import logo from "../../assets/images/Mentor_Logo_Claim.png";
import { useTracking } from "../../utils/tracking";

const useStyles = makeStyles({
  mTitle: {
    color: "black",
    fontSize: "1.2rem",
    fontWeight: 800,
    flexGrow: 1,
    textAlign: "center",
    paddingLeft: 18,
    paddingRight: 18,
    marginBottom: 30,
  },
  dTitle1: {
    color: "black",
    fontSize: "1.6rem",
    fontWeight: 800,
    flexGrow: 1,
    textAlign: "center",
    marginTop: "6vh",
  },
  dTitle2: {
    color: "black",
    fontSize: "1.0rem",
    flexGrow: 1,
    textAlign: "center",
    marginBottom: 20,
  },
  dDescription1: {
    color: "black",
    fontSize: "1.0rem",
    fontWeight: 700,
    flexGrow: 1,
    textAlign: "center",
    marginTop: "5vh",
    marginBottom: "7vh",
  },
  mButtonBox: {
    width: "95%",
    maxWidth: 500,
  },
  mButton: {
    height: 50,
    width: "100%",
    backgroundColor: "white",
    fontWeight: 800,
    fontSize: "1.0rem",
    boxShadow:
      "rgba(255, 0, 0, 0.117647) 0px 1px 6px, rgba(255, 0, 0, 0.117647) 0px 1px 4px", //theme.palette.primary.main,
    borderRadius: 16,
    textTransform: "none",
  },
  dButton: {
    height: 50,
    width: "100%",
    color: "#9E9E9F",
    fontWeight: 800,
    fontSize: "1.0rem",
    textTransform: "none",
  },
});

export default function Intro() {
  const history = useHistory();
  const classes = useStyles();
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 768px)",
  });
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480)",
  });

  const {trackPage} = useTracking();
  useEffect(() => {
    trackPage()
  }, [])

  // const { t } = useTranslation();

  const handleCardClick = (selectedId) => {
    const item = introData.find((item) => item.id === selectedId);
    history.push(item.path);
  };

  const MobileComponents = () => {
    return (
      <div>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: "136px", marginTop: 0 }}
          >
            <img src={logo} alt="" style={{ width: "70vw" }} />
          </Box>
        </Grid>
        <Grid item xs>
          <Typography variant="h2" component="h4" className={classes.mTitle}>
            Lerne den Coach kennen, mit dem du in deine Zukunft startest.
          </Typography>
        </Grid>
        <Grid item className={classes.mButtonBox}>
          <IntroCard key="0" card={introData[0]} onClick={handleCardClick} />
        </Grid>
        <Grid item className={classes.mButtonBox}>
          <Button
            variant="contained"
            className={classes.mButton}
            onClick={() => {
              handleCardClick("2");
            }}
          >
            Alle Coaches durchstöbern
          </Button>
        </Grid>
      </div>
    );
  };

  const DesktopComponents = () => {
    return (
      <div>
        <Grid item xs={12}>
          <Box height="3vh"></Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: "136px" }}
          >
            <img src={logo} alt="" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" component="p" className={classes.dTitle2}>
            <b>
              Bei Mentor findest du starke Coaches, die dich so begleiten, so
              wie du es brauchst.
            </b>{" "}
            <br />
            Wir versprechen dir, dass du schon aus dem kostenlosen
            Kennenlerngespräch
            <br /> <b>wundervolle Erkenntnisse</b> mitnimmst.
            <br />
            Wo willst du hin und wie wirst du dich dann fühlen? Welche Gedanken
            hindern dich daran <br />
            und was kann wieder möglich sein? Was kannst du jetzt tun, um
            weiterzukommen?
          </Typography>
          <Typography variant="h2" component="h4" className={classes.mTitle}>
            Lerne den Coach kennen, mit dem du in deine Zukunft startest.
            <br />
          </Typography>
        </Grid>
        <Grid item className={classes.mButtonBox} justify="center">
          <IntroCard key="0" card={introData[0]} onClick={handleCardClick} />
        </Grid>
        <Grid item className={classes.mButtonBox}>
          <Button
            variant="text"
            className={classes.dButton}
            onClick={() => {
              handleCardClick("2");
            }}
          >
            Diesen Schritt überspringen und direkt zu den Coaches
          </Button>
        </Grid>
      </div>
    );
  };

  return (
    <Container style={{ maxWidth: isTabletOrMobileDevice ? "auto" : "80vw" }}>
      <Grid
        container
        align="center"
        justify="center"
        spacing={isMobileDevice ? 0 : 2}
        // alignItems="center"
      >
        {isTabletOrMobileDevice ? <MobileComponents /> : <DesktopComponents />}
      </Grid>
    </Container>
  );
}
