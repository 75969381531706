import React, { useState, useContext, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import {
  makeStyles,
  Button,
  Container,
  Box,
  CircularProgress,
} from "@material-ui/core";

import { GlobalContext } from "../../store/contexts/Contexts";
import { useHistory } from "react-router";
import { useTracking } from "../../utils/tracking";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(6),
      width: "70vw",
      maxWidth: 300,
    },
  },
  loginButton: {
    marginTop: theme.spacing(4),
    color: "white",
    textTransform: "none",
  },
}));

export default function Login() {
  const { usersState, userLogin, userLogout } = useContext(GlobalContext);

  const classes = useStyles();

  const [username, setUsername] = useState(""); //mentoradmin");
  const [password, setPassword] = useState("");

  const history = useHistory();


  const {trackPage, trackEvent} = useTracking();


  const login = () => {
    trackEvent({ category: 'login',
                action:   'login-try',
              });
    userLogin(username, password)
    .then(
      ({isCoach}) => {
        trackEvent({ category: 'login',
                action:   'login-done',
              });
        isCoach ? history.push("/my-appointments") : history.push("/coaches");
      })
    .catch(() => {
      
    });
  }
  
  useEffect(() => {
    userLogout();
    if (!usersState || !usersState.loggedIn) {
      

      trackPage(); // if loggedIn - we are not staying here (see history.push below)
    }
    // eslint-disable-next-line
  }, []);

  

  useEffect(() => {
    if (usersState && usersState.loggedIn) {
      console.log("userState loggedin? ", usersState.loggedIn, usersState.coach)
      
    }
    // eslint-disable-next-line
  }, [usersState]);

  return (
    <Container
      className={classes.root}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
      }}
    >
      <form style={{ textAlign: "center" }} noValidate autoComplete="off">
        <TextField
          id="standard-basic"
          label="Benutzername/Email"
          value={username}
          inputProps={{ min: 0, style: { textAlign: "center" } }}
          onChange={(e) => setUsername(e.target.value)}
        />
        <br />
        <TextField
          id="outlined-password-input"
          label="Password"
          inputProps={{ min: 0, style: { textAlign: "center" } }}
          type="password"
          autoComplete="current-password"
          // variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        {usersState.loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop="40px"
          >
            <CircularProgress size={26} color="primary" />
          </Box>
        ) : (
          <Button
            className={classes.loginButton}
            variant="contained"
            color="primary"
            onClick={() => login()}
          >
            Anmelden
          </Button>
        )}
        <br />
        <br />
        {usersState.error &&
          " Etwas ging schief bei ihrer Anmeldung! Bitte prüfen Sie Benutzername und Passwort. "}
        {usersState.loggedIn && " Sie sind jetzt angemeldet als "}
        <b> {usersState.loggedIn && "'" + usersState.userEmail + "'"}</b>
      </form>
    </Container>
  );
}
