import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// import { useMediaQuery } from "react-responsive";

// import { useTranslation } from "react-i18next";

import { GlobalContext } from "../../store/contexts/Contexts";
import SalonService, {
  APPOINTMENT_CANCELED,
} from "../../services/SalonService";

// import WithLoading from "../../hoc/WithLoading";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Box, Typography, List, styled } from "@material-ui/core";

import AppointmentItem from "../../components/appointments/AppointmentItem";
import AppointmentItemCoach from "../../components/appointments/AppointmentItemCoach";
import LoadingComponent from "../../components/appointments/LoadingComponent";
import { useTracking } from "../../utils/tracking";

// const ListWithLoading = WithLoading(CoachesList);

const useStyles = makeStyles((theme) => ({
  root: {},
  title1: {
    fontSize: "1.5rem",
    fontWeight: 800,
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  title2: {
    fontSize: "1.0rem",
    fontWeight: 500,
    // textAlign: "center",
  },
}));

export default function MyAppointments() {
  const { usersState } = useContext(GlobalContext);
  const [bookings, setBookings] = useState(null);
  const [pastBookings, setPastBookings] = useState(null);
  const [futureBookings, setFutureBookings] = useState(null);

  const history = useHistory();
  const classes = useStyles();

  const {trackPage} = useTracking();
  useEffect(() => {
    trackPage()
  }, [])

  useEffect(() => {
    if (usersState.coach > 0){
      //console.log("MyAppointments  login coach!!");
      SalonService.getCoachAppointments(usersState.coach, true, (bookingsData) => {
        //console.log("bookingsData");
        //console.log(bookingsData);
        if (bookingsData)
          setBookings(filterBookingsOnStatus(sortBookings(bookingsData)));
      });
    } else {
      SalonService.getUserAppointments(usersState.userId, (bookingsData) => {
        //console.log("bookingsData");
        //console.log(bookingsData);
        if (bookingsData)
          setBookings(filterBookingsOnStatus(sortBookings(bookingsData)));
      });
    }

    // eslint-disable-next-line
  }, [usersState]);

  useEffect(() => {
    handleBookingsData();

    // eslint-disable-next-line
  }, [bookings]);

  // const isMobileDevice = useMediaQuery({
  //   query: "(max-device-width: 480px)",
  // });

  const getDateFromBooking = (booking) => {
    const dateStr = booking.date + " " + booking.time;
    //console.log("getDateFromBooking dateStr",dateStr)
    const date = new Date(dateStr.replace(/-/g, "/"));
    //console.log("getDateFromBooking date" ,date)
    return date;
  };

  const filterBookingsOnStatus = (_bookings) => {
    const _filterBookingsOnStatus = _bookings.filter(
      (a) => a.status !== APPOINTMENT_CANCELED
    );
    return _filterBookingsOnStatus;
  };

  const sortBookings = (_bookings) => {
    const sortedBookings = _bookings.sort(
      (a, b) => {
        
        const comprd = getDateFromBooking(a) - getDateFromBooking(b);
        //console.log("sortBookings comprd", comprd, a, b)
        return comprd;
      }
    );
    //console.log("sortBookings sortedBookings", sortedBookings)
    return sortedBookings;
  };

  function handleBookingsData() {
    if (!bookings || bookings.length <= 0) return;
    const now = new Date();
    const pastB = bookings.filter((a) => getDateFromBooking(a) < now);
    const futureB = bookings.filter((a) => getDateFromBooking(a) >= now);

    //console.log("futureB",bookings, futureB, bookings[0])
    if (pastB) setPastBookings(pastB);
    if (futureB) setFutureBookings(futureB);
  }

  const handleBookingAction = (booking) => {
    //push to details screen
    history.push({
      pathname: "/appointment-details/", // + booking.id,
      state: { bookingId: booking.id, appointmentConfirmed: false },
    });
  };

  const handleCancelBookingAction = (booking) => {
    //push to details screen
    history.push({
      pathname: "/cancel-appointment",
      state: booking.id,
    });
  };

  const HeaderComponent = () => {
    return (
      <Box mt={4} mb={4}>
        <Typography className={classes.title1}>Deine Termine</Typography>
      </Box>
    );
  };

  const Demo = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));

  const ListComponent = ({ items, text, isPastBooking = false }) => {
    return (
      <Demo>
        <Typography className={classes.title2}>{text}</Typography>
        <List>
          {items.map((item) => {
            //console.log(item);
            if (usersState.coach === null) {
              return (
                  <AppointmentItem
                    key={item.id}
                    item={item}
                    isPastBooking={isPastBooking}
                    actionDetails={handleBookingAction}
                    actionCancelAppointment={handleCancelBookingAction}
                  />);
              } else {
                return (
                <AppointmentItemCoach
                  key={item.id}
                  item={item}
                  isPastBooking={isPastBooking}
                  actionDetails={handleBookingAction}
                  actionCancelAppointment={handleCancelBookingAction}
                />);
              }
            
          })}
        </List>
      </Demo>
    );
  };

  return (
    <Container>
      <HeaderComponent />
      <Box
        zIndex="tooltip"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {bookings ? (
          <Box maxWidth="600px">
            {pastBookings && pastBookings.length > 0 && (
              <ListComponent
                items={pastBookings}
                text="Vergangene Buchungen"
                isPastBooking={true}
              />
            )}
            <Box m={4} />
            {futureBookings && futureBookings.length > 0 ? (
              <ListComponent items={futureBookings} text="Aktuelle Termine" />
            ) : (
              <Typography className={classes.title2}>Keine Termine...</Typography>
            )}
          </Box>
        ) : (
          <LoadingComponent />
        )}
      </Box>
    </Container>
  );
}
