import React from "react";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { Avatar, Box, Container, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

import PreviousIcon2 from "../../../assets/images/PreviousIcon2.png";
import NextIcon2 from "../../../assets/images/NextIcon2.png";

import SubjectsAccordion, { ICON_STYLE_2 } from "../SubjectsAccordion";
import DetailsMedia from "./DetailsMedia";
import SwipableTestimonials from "./SwipableTestimonials";

import MTitleAndText from "../MTitleAndText";
import MatchesD from "./MatchesD";
import { isArray } from "util";

const useStyles = makeStyles((theme) => ({
  root: {},
  nextPreviousBox: {
    width: 400,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  previousBtn: {
    //width: "20%",
    padding: 0,
    paddingRight: 20,
  },
  nextBtn: {
    //width: "20%",
    padding: 0,
    paddingLeft: 20,
  },
  icon: {
    width: 15,
    height: 15,
  },
  middleTxt: {
    width: "100%",
    textAlign: "center",
    fontSize: "1.1rem",
    fontWeight: 600,
    color: "black",
  },
  name: {
    fontSize: "2.0rem",
    fontWeight: 800,
    display: "flex",
    justifyContent: "center",
  },
  mottoTxt1: {
    fontSize: "1.1rem",
    fontWeight: 500,
    color: "black",
    paddingRight: 6,
  },
  mottoTxt2: {
    fontSize: "1.2rem",
    fontWeight: 800,
    color: "black",
    display: "flex",
    textAlign: "center",
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 800,
    fontSize: "0.9rem",
    marginTop: 10,
    marginBottom: 10,
  },
  terminBtn: {
    width: "50%",
    color: "white",
    fontSize: "0.9rem",
    fontWeight: 800,
    borderRadius: 20,
    textTransform: "none",
  },
}));

export default function DetailsDesktop({
  coach,
  coachmatches,
  changeCoach,
  terminAction,
  didClickVideo,
  naviToOverview,
}) {
  const excerpt = coach.excerpt.rendered.replace(/<[^>]+>/g, "");

  const content = coach.content.rendered.replace(/<[^>]+>/g, "");

  const meineSchwerpunkte = coach.acf.meine_schwerpunkte_liste
    .map((item) => item.meine_schwerpunkte_text)
    .join(", ");

  const classes = useStyles();

  const preventDefault = (event) => event.preventDefault();

  // useEffect(() => {
  //   //console.log(coach);
  // }, [coach]);

  const NextPreviousComponent = () => {
    return (
      <Box className={classes.nextPreviousBox}>
        <IconButton
          className={classes.previousBtn}
          onClick={() => changeCoach(false)}
        >
          <Avatar src={PreviousIcon2} className={classes.icon} />
        </IconButton>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Typography className={classes.middleTxt} onClick={naviToOverview}>
            Zurück zur Übersicht
          </Typography>
        </Box>
        <IconButton
          className={classes.nextBtn}
          onClick={() => changeCoach(true)}
        >
          <Avatar src={NextIcon2} className={classes.icon} />
        </IconButton>
      </Box>
    );
  };

  const NameAndMotto = ({ fullName, mottoText }) => {
    // let name = fullName.split(" ")[0];
    return (
      <Box>
        <Typography className={classes.name} color="primary">
          {fullName}
        </Typography>
        {mottoText && (
          <Box
            display="flex"
            flexDirection="column"
            marginTop={1}
            justifyContent="center"
            alignItems="center"
          >
            <Typography className={classes.mottoTxt1} inline="true">
              Mein Motto:
            </Typography>
            <Typography className={classes.mottoTxt2} inline="true">
              {mottoText}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  const TerminButton = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          style={{ height: 60 }}
          className={classes.terminBtn}
          onClick={terminAction}
        >
          Jetzt Termin vereinbaren
        </Button>
      </Box>
    );
  };

  return (
    <Container style={{ marginTop: 60 }}>
      {/* <Box display="flex" flexGrow="1" style={{ marginBottom: 20, justifyContent: "center"}}  >
         <NextPreviousComponent />
      </Box> */}
      <Grid container spacing={6}>
        <Grid
          item
          xs={7}
          container
          direction="column"
          spacing={3}
          id="coachgrid_upperarea_left"
        >
          <Grid item xs display="flex">
            <Box flexGrow="1" style={{ paddingTop: 12, paddingBottom: 20 }}>
              <Box
                display="flex"
                flexGrow="1"
                style={{ marginBottom: 50, justifyContent: "center" }}
              >
                <NextPreviousComponent />
              </Box>
              {coachmatches && coachmatches.length > 0 && (
                <MatchesD
                  fullName={coach.title.rendered}
                  percentage={
                    coach.fieldScoreNorm &&
                    parseInt(coach.fieldScoreNorm * 100, 10)
                  }
                  matches={coachmatches}
                  // matches={dummyMatches}
                />
              )}
            </Box>
          </Grid>

          <Grid item xs>
            <NameAndMotto
              fullName={
                coach.title.rendered +
                (coach.fieldScoreNorm
                  ? " - " + parseInt(coach.fieldScoreNorm * 100, 10) + "% Match"
                  : "")
              }
              mottoText={coach.acf.motto}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={5}
          container
          direction="column"
          spacing={3}
          id="coachgrid_upperarea_right"
        >
          <Grid item xs>
            <DetailsMedia
              id="coachdetail_media"
              imageUrl={coach["featured_media"]}
              hasVideo={coach.acf.video_vimeo}
              didClickVideo={didClickVideo}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={7}
          container
          direction="column"
          spacing={3}
          id="coachgrid_lowerarea_left"
        >
          <Grid item xs>
            <TerminButton />
          </Grid>
          <Grid item xs>
            <MTitleAndText title="Was mir wichtig ist:" text={excerpt} />
          </Grid>
          {meineSchwerpunkte && (
            <Grid item xs>
              <MTitleAndText
                title="Meine Schwerpunkte"
                text={meineSchwerpunkte}
              />
            </Grid>
          )}
          {coach.acf.lerne_mich_kennen_wysiwg && (
            <Grid item xs>
              <MTitleAndText
                title="Lerne mich besser kennen"
                text={coach.acf.lerne_mich_kennen_wysiwg}
              />
            </Grid>
          )}
          {coach.acf.zertifikate_link && (
            <Grid item xs>
              <Link
                key={"link"}
                to=""
                href="#"
                onClick={preventDefault}
                color="primary"
                className={classes.link}
              >
                {">> Hier findest du meine Zertifikate"}
              </Link>
            </Grid>
          )}
          {/* <Grid item xs>
            <MTitleAndText title="Lerne mich besser kennen" text={excerpt} />
          </Grid> */}
          <Grid item xs>
            {coach &&
              coach.acf &&
              coach.acf.testimonials &&
              isArray(coach.acf.testimonials) &&
              coach.acf.testimonials.length > 0 && (
                <SwipableTestimonials
                  coachname={coach.title.rendered}
                  testimonials={coach.acf.testimonials}
                />
              )}
          </Grid>
          {coach.acf.meine_erfahrungen_text && (
            <Grid item xs>
              <MTitleAndText
                title="Meine Erfahrungen"
                text={coach.acf.meine_erfahrungen_text}
              />
            </Grid>
          )}

          <Grid item xs>
            <TerminButton />
          </Grid>

          {content && (
            <Grid item xs>
              <MTitleAndText title="Mehr über mich:" text={content} />
            </Grid>
          )}
          <Grid item xs style={{ justifyContent: "center", display: "flex" }}>
            <NextPreviousComponent />
          </Grid>
        </Grid>
        <Grid
          item
          xs={5}
          container
          direction="column"
          spacing={3}
          id="coachgrid_lowerarea_right"
        >
          <Grid item xs>
            <SubjectsAccordion
              title={"Meine 5 Top Themen"}
              subjects={coach["coachfinder_subject_sorted"].slice(0, 5)}
              expanded={true}
              iconStyle={ICON_STYLE_2}
            />

            <SubjectsAccordion
              title={"Meine 5 Top Arbeitsansätze"}
              subjects={coach["coachfinder_approach_sorted"].slice(0, 5)}
              expanded={true}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
