import React from "react";

import { makeStyles } from "@material-ui/core";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  matchIconBox: {
    display: "flex",
    justifyContent: "center",
    borderRadius: 16,
    backgroundColor: theme.palette.primary.main,
    height: 80,
    width: 80,
  },
  matchIcon: {
    width: 60,
    height: 60,
  },
  matchTitle: {
    //height: 30,
    color: "black",
    padding: 10,
    //paddingBottom: 10,
    fontSize: "0.8rem",
    fontWeight: 700,
    textAlign: "center",
  },
}));

const MatchBox = ({ icon, title }) => {
  const classes = useStyles();

  return (
    <Box
      height="110px"
      width="33%"
      
      flexGrow={1}
      flexDirection="column"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
      >
        <Box alignItems="center" className={classes.matchIconBox}>
          <img alt={title} src={icon} className={classes.matchIcon}></img>
        </Box>
      </Box>
      <Typography className={classes.matchTitle}>{title}</Typography>
    </Box>
  );
};

export default MatchBox;
