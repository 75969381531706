export const subjects = [
  {
    name: "Kommunikation im Team",
    sortField: "coachfinder_subject_communicationteam",
  },
  {
    name: "Veränderung am Arbeitsplatz",
    sortField: "coachfinder_subject_changemanagement",
  },
  { name: "Führung", sortField: "coachfinder_subject_leadership" },
  { name: "Belästigung", sortField: "coachfinder_subject_molesting" },
  {
    name: "Stress & Überlastung",
    sortField: "coachfinder_subject_stress",
  },
  {
    name: "Behandlungsfehler",
    sortField: "coachfinder_subject_treatmenterrors",
  },
  {
    name: "Trauer & Verlust",
    sortField: "coachfinder_subject_griefloss",
  },
  {
    name: "Unsicherheit & Blockaden",
    sortField: "coachfinder_subject_insecurities",
  },
  {
    name: "Schlafprobleme",
    sortField: "coachfinder_subject_sleepproblems",
  },
  {
    name: "Angst & Hoffnungslosigkeit",
    sortField: "coachfinder_subject_fearhopelessness",
  },
  {
    name: "Sucht / Alkoholprobleme",
    sortField: "coachfinder_subject_addiction",
  },
  {
    name: "Innere Neuausrichtung",
    sortField: "coachfinder_subject_inneralignment",
  },
  {
    name: "Finanzielle Probleme",
    sortField: "coachfinder_subject_financialproblems",
  },
  {
    name: "Private Probleme",
    sortField: "coachfinder_subject_privateproblems",
  },
  {
    name: "Probleme mit Kindern",
    sortField: "coachfinder_subject_problemswithkids",
  },
];
