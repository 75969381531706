import React, { useEffect, useState } from "react";

import ImageService from "../../../services/ImageService";


import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';

import Skeleton from "@material-ui/lab/Skeleton";
import { Box, Button, CardMedia, Typography, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mediaBox: { width: "100%", height: "100%", display: "flex", position: "relative" },
  media: {
    width: "100%",
    textAlign: "center",
    borderRadius: 16,
    // objectFit: "fill",
  },
  name: {
    fontSize: "1.2rem",
    fontWeight: 800,
    marginTop: 5,
    display: "flex",
    height: 50,
    width: "90%",
  },
  motoTxt: {
    fontSize: "1.2rem",
    fontWeight: 800,
    color: "black",
    marginTop: 20,
    display: "flex",
  },
  terminBtn: {
    width: "80%",
    color: "white",
    fontSize: "0.9rem",
    fontWeight: 800,
    borderRadius: 10,
    textTransform: "none",
    marginTop: 20,
  },
  percentageBox: {
    backgroundColor: "white",
    width: 80,
    height: 50,
    position: "absolute",
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  percentageLabel: {
    fontWeight: 800,
    fontSize: "1.8rem",
    color: "orange",
    textAlign: "center",
  },
}));

function TopSection({ name, imageUrl, percentage, terminAction, didClickVideo, hasVideo, motto }) {
  const [media, setMedia] = useState({
    loading: true,
    image: null,
  });

  const classes = useStyles();

  const VideoButton = ({ id }) => {
    return (
      // <Box className={classes.mediaItemsBox}>
        <IconButton
          children={
            <PlayCircleOutlineRoundedIcon
              color="primary"
              style={{ transform: "scale(2.2)" }}
              fontSize="large"
            />
          }
          onClick={() => didClickVideo()}
        />
      // </Box>
    );
  };

  const getMedia = () => {
    setMedia({ loading: true, image: null });
    ImageService.getMediaWithId(imageUrl, (imageUrl) => {
      setMedia({ loading: false, image: imageUrl });
    });
  };

  useEffect(() => {
    getMedia();

    // eslint-disable-next-line
  }, [imageUrl]);

  return (
    <Box display="flex" flexDirection="row">
      <Box width="50%">
        <Typography>Coachprofil</Typography>
        <Typography className={classes.name} color="primary">
          {name}
        </Typography>
        {motto && <Typography className={classes.motoTxt}>
          {motto}
        </Typography>
        }
        <Box>
          <Button
            variant="contained"
            color="primary"
            className={classes.terminBtn}
            onClick={terminAction}
          >
            Jetzt Termin vereinbaren
          </Button>
        </Box>
      </Box>
      <Box
        width="50%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Box className={classes.mediaBox}>
          {media.loading ? (
            <Skeleton
              animation="wave"
              variant="rect"
              className={classes.media}
            />
          ) : (
            <CardMedia
              className={classes.media}
              image={media.image}
              component="img"
              alt="image"
              display="flex"
              height={percentage ? "92%" : "100%"}
            />
          )}
          { hasVideo && 
          <div style={{ position: "absolute", 
                          opacity: 0.6,
                          top: "50%", 
                          width: "100%",
                          display: "flex", 
                          justifyContent: "center" }}>
            <VideoButton />
          </div>
        }
        </Box>
        {percentage && (
          <Box className={classes.percentageBox} boxShadow={1}>
            <Typography className={classes.percentageLabel}>
              {percentage}%
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default TopSection;
