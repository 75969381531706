import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import { Box, Button, Grid, Typography } from "@material-ui/core";
import { TYPE_ONE } from "../../data/QuestionsData";

const useStyles = makeStyles((theme) => ({
  middleGrid: {
    width: "100%",
    minHeight: "75vh",
  },
  iconBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  icon: {
    width: "45px",
    margin: "7px",
    display: "flex",
    alignItems: "center",
  },
  title: {
    paddingLeft: "6vw",
    paddingRight: "6vw",
    textAlign: "center",
    fontSize: "1.2rem",
    fontWeight: 800,
  },
  answers: {
    width: "100%",
    textAlign: "center",
  },
  answerBtn: {
    color: "black", //theme.palette.primary.main,
    backgroundColor: "white",
    fontWeight: 800,
    fontSize: "0.9rem",
    margin: "1.0vh",
    width: "80%",
    height: 54,
    borderRadius: "2vh",
    borderColor: theme.palette.primary.light,
    borderWidth: 1,
    borderStyle: "solid",
    textTransform: "none",
    justifyContent: "flex-start",
    textAlign: "left",
  },
  answerSelected: {
    color: "white",
    backgroundColor: theme.palette.primary.main + "!important",
  },
  leftIconBox: {
    backgroundColor: theme.palette.primary.main,
    width: 36,
    height: 36,
    marginLeft: 8,
    marginRight: 5,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
  },
  leftIconBoxSelected: {
    backgroundColor: "white !important",
  },
  leftIconText: {
    color: "white",
    fontWeight: 800,
    fontSize: "1.3rem",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
  },
  leftIconTextSelected: {
    color: theme.palette.primary.main + "!important",
  },
  subjectIcon: {
    width: 36,
    marginLeft: 10,
    marginRight: 8,
    display: "flex",
    alignItems: "center",
  },
}));

export default function QuestionTypeOne({
  question,
  previousAnswer,
  callBack,
}) {
  const classes = useStyles();

  function isAnswerSelected(ans) {
    return previousAnswer != null && previousAnswer.answer.value === ans.value;
  }

  function getIcon(ans) {
    return ans.icon && isAnswerSelected(ans) ? ans.icon.white : ans.icon.yellow;
  }

  const letterForIndex = (index) => {
    switch (index + 1) {
      case 4:
        return "D";
      case 3:
        return "C";
      case 2:
        return "B";
      default:
        return "A";
    }
  };
  const ButtonIcon = ({ answer, answerIndex }) => {
    return (
      <Box
        className={`${classes.leftIconBox} ${
          isAnswerSelected(answer) && classes.leftIconBoxSelected
        }`}
      >
        <Typography
          className={`${classes.leftIconText} ${
            isAnswerSelected(answer) && classes.leftIconTextSelected
          }`}
        >
          {letterForIndex(answerIndex)}
        </Typography>
      </Box>
    );
  };

  return (
    <Grid
      item
      container
      className={classes.middleGrid}
      justify="center"
      alignContent="space-around"
      spacing={0}
    >
      {question.type === TYPE_ONE && (
        <Grid item>
          <Box className={classes.iconBox}>
            <img
              alt={question.id}
              src={question.icon.yellow}
              className={classes.icon}
            />
          </Box>
        </Grid>
      )}
      <Grid item>
        <Typography className={classes.title}>{question.text}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.answers} flexDirection="column">
          {question.answers.map((answer, index) => {
            return (
              <Button
                key={index}
                startIcon={
                  question.type === TYPE_ONE ? (
                    <ButtonIcon answer={answer} answerIndex={index} />
                  ) : (
                    <img
                      alt={answer.value}
                      src={getIcon(answer)}
                      className={classes.subjectIcon}
                    />
                  )
                }
                size="medium"
                className={`${classes.answerBtn} ${
                  previousAnswer != null &&
                  previousAnswer.answer.value === answer.value &&
                  classes.answerSelected
                }`}
                onClick={() => callBack(answer)} //didSelectAnswer(answer)}
              >
                {answer.text}
              </Button>
            );
          })}
        </Box>
      </Grid>
    </Grid>
  );
}
