import Joi from 'joi';

import { TYPE_ONE, TYPE_THREE } from "../../data/QuestionsData";
import { SUBJECT, FOCUS } from "../../utils/helpers";

export const schemaAnswerObject =  Joi.object().keys({value : Joi.number().required() }).unknown(true);
export const schemaAnswersObjectApproach =  Joi.object().keys({ id: Joi.number().required(), value: Joi.number().required(), text: Joi.string().required() }).unknown(false);
export const schemaAnswersObjectSubject =  Joi.object().keys({ 
                                                        id: Joi.number().required(), 
                                                        value: Joi.number().required(), 
                                                        text: Joi.string().required(), 
                                                        name: Joi.string().required(),
                                                        icon: Joi.object().keys({yellow : Joi.string().required(), white : Joi.string().required()}) ,
                                                        field: Joi.string().required() 
                                                      }).unknown(false);

export const schemaQuestionObject = Joi.object().keys({
  id: Joi.number().required(),
  name: Joi.string().required(),
  text: Joi.string().required(),
  type: Joi.string().valid(TYPE_ONE, TYPE_THREE).required(), 
  fieldtype: Joi.string().valid(SUBJECT, FOCUS).required(),
  field: Joi.string().required(), // pattern ?
  icon: Joi.any().allow(Joi.string(),null),
  answers:  Joi.array().items(
    Joi.alternatives().try(
      schemaAnswersObjectApproach,schemaAnswersObjectSubject)  ).required(),
}).unknown(false);

export const schemaAnswerFilled = Joi.object({

  question:  schemaQuestionObject.required(),
  answer:  Joi.alternatives().try(
    schemaAnswerObject,
    Joi.array().items(schemaAnswerObject ),
  ).required(),

  answerWeight : Joi.number().required(),

}).unknown(true);


export const checkValidAnswer = (answer) => {
  return schemaAnswerFilled.validate(answer);
}