import React from "react";

import { CircularProgress } from "@material-ui/core";
import { Box } from "@mui/system";

export default function LoadingComponent() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="30vh"
    >
      <CircularProgress disableShrink />
    </Box>
  );
}
