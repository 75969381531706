import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@material-ui/core";

import { Button, DialogContentText, Grid } from "@mui/material";
import AppointmentDetailsItem from "../../components/appointments/AppointmentDetailsItem";
import AppointmentDetailsItemCoach from "../../components/appointments/AppointmentDetailsItemCoach";
import SalonService, {
  APPOINTMENT_CANCELED,
} from "../../services/SalonService";
import LoadingComponent from "../../components/appointments/LoadingComponent";
import { GlobalContext } from "../../store/contexts/Contexts";
import { Notes } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
    marginTop: 20,
    marginBottom: 30,
    fontSize: "1.5rem",
    fontWeight: 900,
  },
  terminTxt: {
    margin: 10,
  },
  status: {
    marginLeft: 10,
    fontSize: "1.0rem",
    fontWeight: 600,
  },
  infoTitle: {
    textAlign: "center",
    fontSize: "1.2rem",
    fontWeight: 600,
  },
  textfield: {
    width: "100%",
    marginTop: 10,
  },
  okButton: {
    width: "30%",
    fontSize: "0.9rem",
    fontWeight: 800,
    borderRadius: 20,
    textTransform: "none",
  },
  cancelButton: {
    width: "30%",
    color: theme.palette.primary.main,
    fontSize: "0.6rem",
    fontWeight: 500,
    borderRadius: 20,
    textTransform: "none",
  },
}));

export default function CancelAppointment() {
  const { goBack } = useContext(GlobalContext);
  const { coachesState, usersState } = useContext(GlobalContext);
  const [booking, setBooking] = useState(null);
  const [coach, setCoach] = useState(null);
  const [canceling, setCanceling] = useState({
    loading: false,
    isCanceled: false,
  });

  const [answers, setAnswers] = useState([]);
  const [textReason, setTextReason] = useState(undefined);

    const handleTextReasonChange = event => {
      setTextReason(event.target.value);
    };

  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();

  const bookingId = location.state;

  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });

  useEffect(() => {
    if ((bookingId && !booking)|| booking.id !== bookingId) {
      SalonService.getAppointmentWithId(bookingId, (items) => {
        if (items) {
          setBooking(items[0]);
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    
    console.log("Cancel booking", booking)
    const newcoach = getCoachByBooking(booking);
    if (newcoach) setCoach(newcoach);
    console.log("Cancel booking newcoach", newcoach)
    // eslint-disable-next-line
  }, [booking]);


  const getCoachByBooking = (booking) => {

    if (!booking || !booking.services || !booking.services[0]){
      return null;
    }

    const assistentId = booking.services[0].assistant_id;
    if (!coachesState.coaches) {
      return;
    }
    if (assistentId) {
      const findCoach = coachesState.coaches.filter(
        (c) => c && c.acf && c.acf.coach_assistent === assistentId
      );
      const coach = findCoach.length > 0 ? findCoach[0] : null;
      return coach;
    }

  }

  function getNotes() {
    
    var questionAnswers = answers.reduce((acc,id) => {
      acc += questions.find(q => q.id === id).question + "\n,"
      return acc;
    }, "" );
    const notes = questionAnswers + " \nanderer Grund: " + (textReason ? textReason : "(keine anderen Gründe genannt)");
    console.log("notes");
    console.log(notes);
    return notes;
  }

  const cancelBooking = () => {
    setCanceling({ loading: true, isCanceled: false });
    SalonService.updateAppointment(
      bookingId,
      APPOINTMENT_CANCELED,
      getNotes(),
      (success) => {
        if (success) {
          setCanceling({ loading: false, isCanceled: true });
        } else {
          setCanceling({ loading: false, isCanceled: false });
        }
      }
    );
  };

  const questions = usersState.coach === null ? [
      { id: 0, question: "Ich wollte einen anderen Termin buchen." },
      { id: 1, question: "Ich möchte den Coach wechseln." },
      { id: 2, question: "Ich möchte alleine meinen Weg gehen." },
      { id: 3, question: "Ich habe leider einen wichtigen anderen Termin." },
    ] : [
      { id: 0, question: "Der Klient wollte einen anderen Termin buchen." },
      //{ id: 1, question: "Ich möchte den Coach wechseln." },
      { id: 2, question: "Der Klient hat abgesagt." },
      { id: 3, question: "Ich habe leider einen wichtigen anderen Termin." },
    ];

  function handleQuestionChange({ id, isChecked }) {
    //console.log("did tap question ", isChecked, " ", id);
    updateAnswer(id, isChecked);
  }

  

  function updateAnswer(id, isChecked) {
    var findItem = answers.filter((_id) => _id === id);
    var _answers = answers;

    if (isChecked) {
      if (findItem.length === 0) {
        _answers.push(id);
      }
    } else if (findItem.length > 0) {
      _answers = _answers.filter((_id) => _id !== id);
    }

    setAnswers([..._answers]);

    
  }

  function isAnswerChecked(id) {
    const findItem = answers.filter((_id) => _id === id);
    return findItem.length > 0;
  }

 
  const AppointmentDeleted = () => {
    return (
      <div>
        <Dialog
          open={true}
          //   onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Der Termin wurde abgesagt!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Vielen Dank für das Bescheid geben und bis bald beim nächsten Termin!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => history.push("/my-appointments")}>
              Zu den Terminen
            </Button>
            <Button onClick={() => history.push("/coaches")}>
              Zu den Coaches
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  console.log("Render Cancel", booking, coach)
  return (
    <Container className={classes.root}>
      <Box maxWidth="600px">
        {!booking 
          ?   
          <LoadingComponent /> 
          :
          <Grid container direction="column">
            <Grid item>
              <Typography className={classes.title} color="primary">Buchung absagen</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.terminTxt}>
                Du möchteste deinen Termin absagen?:
              </Typography>
            </Grid>
            <Grid item>
              {usersState.coach === null && 
                <AppointmentDetailsItem booking={booking} coach={coach} showContacts={false} />
              }
              {usersState.coach !== null && 
                <AppointmentDetailsItemCoach booking={booking} coach={coach} showContacts={false} />
              }
            
            </Grid>
            <Grid item>
              <Box display="flex" flexDirection="column" marginTop={8}>
                <Typography className={classes.infoTitle}>
                  Schade, dass es mit diesem Termin nicht geklappt hat.
                </Typography>
                <Typography className={classes.infoTitle}>
                  Könntest du uns wissen lassen, woran es lag?
                </Typography>
              </Box>
            </Grid>
            <Grid item style={{display: "flex", justifyContent: "center"}} >
              <Box display="flex" flexDirection="column" marginTop={4} style={{maxWidth:"90%"}}>
                <FormGroup>
                  {questions.map((item) => {
                    return (
                      <FormControlLabel
                        key={"quest" + item.id}
                        control={<Checkbox key={item.id} />}
                        label={item.question}
                        checked={isAnswerChecked(item.id)}
                        onChange={(event, isChecked) =>
                          handleQuestionChange({ id: item.id, isChecked })
                        }
                      />
                    );
                  })}
                  <TextField
                    variant="outlined"
                    size="small"
                    label="andere Gründe"
                    className={classes.textfield}
                    key={'textReason'}
                    id="textreason"
                    value={textReason}
                    onChange={handleTextReasonChange}
                    />
                </FormGroup>
                
              </Box>
            </Grid>
            <Grid item>
              {canceling.loading ? (
                <LoadingComponent />
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginTop={6}
                >
                  {canceling.isCanceled ? (
                    <AppointmentDeleted />
                  ) : (
                    <Button
                      variant="outlined"
                      // color="primary"
                      className={classes.okButton}
                      style={{
                        color: "red",
                        borderColor: "red",
                        textTransform: "none",
                      }}
                      onClick={cancelBooking}
                    >
                      Termin Absagen!
                    </Button>
                  )}
                </Box>
              )}
            </Grid>
            <Grid item>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop={1}
                marginBottom={6}
              >
                <Button
                  variant="outlined"
                  className={classes.cancelButton}
                  style={{
                    color: "grey",
                    borderColor: "grey",
                    textTransform: "none",
                    
                  }}
                  onClick={() => goBack()}
                >
                  Abbrechen
                </Button>
              </Box>
            </Grid>
          </Grid>
        }
      </Box>
    </Container>
  );
}
