import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";

import { applyContainerQuery } from "react-container-query";

import ImageService from "../../services/ImageService";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";

import { motion } from "framer-motion";

import Grid from "@material-ui/core/Grid";
import { GlobalContext } from "../../store/contexts/Contexts";
import { Box, CardContent, Button } from "@material-ui/core";

const spring = {
  type: "spring",
  damping: 25,
  stiffness: 120,
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "24vh",
    display: "flex",
    borderRadius: 16,
    borderColor: "grey",
    borderWidth: 1,
  },
  rootHeightUp: {
    minHeight: "150px",
  },
  rootHeightDown: {
    minHeight: "250px",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  mediaUp: {
    width: "50%",
  },
  mediaDown: {
    width: "40%",
  },
  title: {
    fontSize: "1.1rem",
    fontWeight: 800,
    textAlign: "left",
    flexGrow: 1,
  },
  field1: {
    fontSize: "1.6rem",
    textAlign: "left",
    fontWeight: 800,
    marginRight: "6px",
  },
  field2: {
    height: "100%",
    fontSize: "0.6rem",
    textAlign: "center",
    fontWeight: 400,
    //marginLeft: "6px",
    paddingBottom: 10,
  },
  mButton1: {
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontWeight: 800,
    fontSize: "0.6rem",
    boxShadow:
      "rgba(255, 0, 0, 0.117647) 0px 1px 6px, rgba(255, 0, 0, 0.117647) 0px 1px 4px", //theme.palette.primary.main,
    borderRadius: 16,
  },
  mButton2: {
    width: "100%",
    color: theme.palette.primary.main,
    fontWeight: 800,
    fontSize: "0.7rem",
    boxShadow:
      "rgba(255, 0, 0, 0.117647) 0px 1px 6px, rgba(255, 0, 0, 0.117647) 0px 1px 4px", //theme.palette.primary.main,
    borderRadius: 16,
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    marginTop: 8,
    marginBottom: 5,
  },
}));

function withAnimation(component, _key) {
  // workaorund for motion.div animation to animate sorting of Grid-items: Grid-item-classes are added to motion.div:
  return (
    <motion.div
      className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4"
      key={_key}
      layout
      transition={spring}
    >
      {component}
    </motion.div>
  );
}

function withoutAnimation(component) {
  return (
    <Grid item xs={12} sm={4}>
      {component}
    </Grid>
  );
}

const containerQuery = {
  widthTopCoachLarger300: {
    minWidth: 300,
  },
  widthTopCoachSmaller300: {
    maxWidth: 300,
  },
  widthTopCoachLarger350: {
    minWidth: 350,
  },
  widthTopCoachSmaller220: {
    maxWidth: 220,
  },
  widthTopCoachLarger410: {
    minWidth: 410,
  },
};

function TopCoach({ coach, _key, animate, containerQuery, onCoachSelect }) {
  const { coachesState } = useContext(GlobalContext);
  // const isTabletOrMobileDevice = useMediaQuery({
  //   query: "(max-device-width: 768px)",
  // });
  // for testing with no applyContainerQuery:
  // let containerQuery = {}
  // containerQuery = {
  //   'widthTopCoachLarger300': () => {} ,
  //   'widthTopCoachSmaller300': () => {} ,
  //   'widthTopCoachLarger350':  () => {} ,
  //   'widthTopCoachSmaller220': () => {} ,
  //   'widthTopCoachLarger410':  () => {} ,
  // };

  const history = useHistory();
  const classes = useStyles();
  const mounted = useRef(false);

  const [media, setMedia] = useState({
    loading: true,
    image: null,
  });

  const getMedia = () => {
    setMedia({ loading: true, image: null });
    ImageService.getMediaWithId(coach["featured_media"], (imageUrl) => {
      if (mounted.current) {
        setMedia({ loading: false, image: imageUrl });
      }
    });
  };

  useEffect(() => {
    //console.log("containerQuery", containerQuery);
  }, [containerQuery]);

  useEffect(() => {
    getMedia();

    mounted.current = true;

    return function cleanup() {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getMedia();
    // eslint-disable-next-line
  }, [coachesState]);

  const classnamesRoot = (query) => {
    return `${classes.root} ${
      query.widthTopCoachLarger410
        ? classes.rootHeightUp
        : classes.rootHeightDown
    }`;
  };
  const classnamesMedia = (query) => {
    return query.widthTopCoachLarger350 ? classes.mediaUp : classes.mediaDown;
  };

  const didSelectCoach = () => {
    history.push({
      pathname: "/coaches/" + coach.slug,
      state: coach,
    });
    if (onCoachSelect) onCoachSelect(coach)
  };

  const coachSpeciality = coach && coach.acf && coach.acf.speciality ? coach.acf.speciality : null;


  const coachcard = (
    <Card className={classnamesRoot(containerQuery)} style={{position: "relative"}}>
      {media.loading ? (
        <Skeleton
          animation="wave"
          variant="rect"
          className={classnamesMedia(containerQuery)}
        />
      ) : (
     
          <CardMedia
            className={classnamesMedia(containerQuery)}
            
            image={media.image}
            component="img"
            alt="image"
            onClick={() => didSelectCoach()}
          />
              
              
               
      )}
      {/* <Box style={{
          backgroundColor: "rgb(255,255,255,0.9)", 
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          padding: "8px 8px 5px 10px"
          }}>
          <Typography
                  color="primary"
                  style={{
                    fontSize: `0.5rem`,
                    lineHeight: '1rem',
                    textAlign: "left",
                    lineHeight: "0.2rem",
                    fontWeight: 800,
                    color: "#ccc",
                  }}
                >
                  Expertise in/als
                </Typography>
                <Typography
                  color="primary"
                  style={{
                    fontSize: `0.8rem`,
                    fontWeight: 800,
                    textAlign: "left",
                    
                  }}
                  >
                  {coachSpeciality}
                </Typography>
        </Box> */}
        {/* {coachSpeciality && (<Box style={{
                position: "absolute", 
                background: "red",
                color:"white",
                bottom: 15,
                left: 0,
                transform: "rotate(0.03turn)",
                padding: "2px 4px",
                }}>{coachSpeciality}</Box>
                )
              } */}
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <div style={{ width: "100%", marginTop: -10 }}>
            <Box>
              
              <Typography className={classes.title}>
                {coach.title.rendered}
              </Typography>
              
              <Typography
                  color="primary"
                  style={{
                    paddingTop: 5,
                    fontSize: `0.5rem`,
                    lineHeight: '1rem',
                    textAlign: "left",
                    lineHeight: "0.2rem",
                    fontWeight: 800,
                    color: "#ccc",
                  }}
                >
                  Expertise in/als
                </Typography>
                <Typography
                  color="primary"
                  style={{
                    fontSize: `0.8rem`,
                    fontWeight: 800,
                    textAlign: "left",
                    lineHeight: '0.8rem',
                    paddingTop: 5
                  }}
                  >
                  {coachSpeciality}
                </Typography>


            </Box>

            <Box
              display="flex"
              flexDirection={
                containerQuery.widthTopCoachLarger300 ? "row" : "column"
              }
              alignItems={
                containerQuery.widthTopCoachLarger300
                  ? "flex-end"
                  : "flex-start"
              }
            >
              {coach.fieldScoreNorm && (
                <Typography className={classes.field1} color="primary">
                  {coach.fieldScoreNorm * 100 + "%"}
                </Typography>
              )}
              {!containerQuery.widthTopCoachSmaller220 && (
                <Typography className={classes.field2}
                  style={{
                    fontSize: `0.5rem`,
                    lineHeight: '0.5rem',
                    color: "#ccc",
                    fontWeight: 800
                  }}>
                  Übereinstimmung
                </Typography>
              )}
            </Box>

            <Box paddingTop="2vh" style={{position: "relative"}}>
              <Button
                className={classes.mButton1}
                onClick={() =>

                  {
                    if (coach.acf.appointmentType && coach.acf.appointmentType === "hubspot"){
                      history.push("/hubspot/" + coach.slug);
                    }else{
                      history.push({
                        pathname: "/appointment/" + coach.slug, //"/hubspot/" + coach.slug,
                      });
                    }
                  }} >
                Termin {containerQuery.widthTopCoachLarger300 && "vereinbaren"}
              </Button>

              <Button
                className={classes.mButton2}
                onClick={() => didSelectCoach()}
              >
                Mehr {containerQuery.widthTopCoachLarger300 && "erfahren"}
              </Button>

                

              
            </Box>
          </div>
        </CardContent>
      </div>
    </Card>
  );

  return animate ? withAnimation(coachcard, _key) : withoutAnimation(coachcard);
}

export default applyContainerQuery(TopCoach, containerQuery);
