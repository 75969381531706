import {
  iconsApproachYellow,
  iconsApproachWhite,
  iconsSubjectWhite,
  iconsSubjectYellow,
} from "./requireContextLoader";

export const FOCUS = "focus-sort";
export const SUBJECT = "subject-sort";


export const mergeArrays = (oldArray, newArray, key, key2) => {
  var reduced = oldArray.filter((oldItem) => {
    if (key2) {
      return !newArray.find(
        (newItem) => oldItem[key][key2] === newItem[key][key2]
      );
    } else {
      return !newArray.find((newItem) => oldItem[key] === newItem[key]);
    }
  });
  return reduced.concat(newArray);
};

export const findOneEntry = (array, keys, value, defaultValue) => {

  var results = array.filter((e) => {
    const valueOfElement = keys.reduce((acc,key) => {
      return acc ? acc[key] : {error : "notfound"};
    },e)
    //console.log("findOneEntry",(valueOfElement === value),valueOfElement, value);
    return valueOfElement === value ;
  });
  //console.log("findOneEntry results",results);
  var result = results.length > 0 ? results[0] : null;
  return result;
};

export function mIcon(type = FOCUS, name) {
  switch (type) {
    case FOCUS:
      // TODO: Berufliche Orientierung png is converted to inline data/image -> prevent that from happening

      const ay = name.includes("Beruf")
        ? iconsApproachYellow[0].default
        : iconsApproachYellow.filter((image) =>
            image.default.includes(name)
          )[0].default;
      const aw = name.includes("Beruf")
        ? iconsApproachWhite[0].default
        : iconsApproachWhite.filter((image) =>
            image.default.includes(name)
          )[0].default;

      //console.log("mIcon", aw,ay,iconsApproachYellow[4].default.toString(),iconsApproachYellow);
      return { yellow: ay, white: aw };

    case SUBJECT:
      const sy = iconsSubjectYellow.filter((image) =>
        image.default.includes(name)
      )[0].default;
      const sw = iconsSubjectWhite.filter((image) =>
        image.default.includes(name)
      )[0].default;
      return { yellow: sy, white: sw };

    default:
      return "";
  }
}
