import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import { Container, Grid, Typography } from "@material-ui/core";
import AnswerBox from "./AnswerBox";

const useStyles = makeStyles((theme) => ({
  middleGrid: {
    width: "100%",
    minHeight: "75vh",
  },
  title: {
    textAlign: "center",
    marginTop: "1vh",
    fontSize: "1.1rem",
    fontWeight: "800",
  },
  box: {
    height: "6vh",
    display: "flex",
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center",
  },
  list: {
    height: "80%",
    paddingTop: "2vh",
  },
}));

export default function QuestionTypeThree({
  question,
  previousAnswer,
  callBack,
}) {
  const classes = useStyles();

  const [answers, setAnswers] = useState([])


  useEffect(() => {
    if (previousAnswer && previousAnswer.answer && Array.isArray(previousAnswer.answer)) {
      setAnswers(previousAnswer.answer);
    }

    //console.log("previousAnswer", previousAnswer)

  }, [previousAnswer])

  const toggleAnswers = (currentAns) => {


    
    //const idx = answers.findIndex((a) => a.field === ans.field);

    const currentAnswerFields = answers.filter(a => a.value > 0).map(a => a.field); // all values > 0 
    const idxCurrField = currentAnswerFields.indexOf(currentAns.field);
    if (idxCurrField >= 0) {
      currentAnswerFields.splice(idxCurrField,1); // toggle: remove if field was in answers before
    } else {
      currentAnswerFields.push(currentAns.field); // toggle: add if field was not in answers before
    }

    // go through all question answers and set value
    let newAnswers = question.answers.map(a => {
              return {  ...a, 
                        value: currentAnswerFields.includes(a.field) 
                                  ? 1
                                  : 0 }
              });
    
    //console.log("toggleAnswers newAnswers",newAnswers)
    
    setAnswers(newAnswers);
    callBack(newAnswers);
  }

  const selectFields = previousAnswer != null && previousAnswer.answer && previousAnswer.answer.filter  
                          ?  previousAnswer.answer.filter(pAns => pAns.value > 0).map(pAns => pAns.field) 
                          : [];

  return (
    <Container className={classes.content}>
      <Typography className={classes.title}>{question.text}</Typography>
      <Grid container spacing={2} className={classes.list}>
        {question.answers.map((qanswer, index) => (
          <AnswerBox
            key={index}
            answer={qanswer}
            isSelected={
              selectFields.includes(qanswer.field)
            }
            callBack={toggleAnswers}
          />
        ))}
      </Grid>
    </Container>
  );
}
