import React from "react";
import { useMediaQuery } from "react-responsive";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";

import { Box, CardMedia } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: "auto",
    marginBottom: "4vh",
    maxHeight: "320px",
    border: "none",
    boxShadow:
      "rgba(255, 0, 0, 0.117647) 0px 1px 6px, rgba(255, 0, 0, 0.117647) 0px 1px 4px", //theme.palette.primary.main,
    borderRadius: 16,
    position: "relative",
  },
  media: {
    height: 0,
    borderRadius: 16,
    paddingBottom: 0,
  },
  mediaIntro: {
    paddingTop: "56.25%",
    paddingBottom: "8.25%",
    maxWidth: "100%",
  },
  mediaCoaches: {
    paddingTop: "45.25%",
    maxWidth: "92%",
  },
  content: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    display: "flex",
    width: "55%",
    height: "100%",
    paddingLeft: "3vw",
    flexDirection: "column",
  },
  titleBox: {
    display: "flex",
    flexGrow: "1",
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  title: {
    color: "black",
    fontWeight: 800,
    paddingBottom: 10,
    textAlign: "start",
  },
  description: {
    color: "black",
    flexGrow: 1,
    paddingBottom: 10,
    textAlign: "start",
  },
}));

export default function IntroCard({ card, onClick }) {
  const classes = useStyles();
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 768px)",
  });

  return (
    <Grid item xs={12} display="flex">
      <div>
        <Card
          className={classes.card}
          elevation={4}
          style={{
            display: "flex",
            marginLeft: 0,
            marginRight: 0,
            backgroundColor: `${card.id !== "2" ? "#FBBC42" : "white"}`,
          }}
        >
          <CardActionArea
            onClick={() => onClick(card.id)}
            style={{ paddingBottom: 0 }}
          >
            <CardMedia
              image={card.icon} //{card.id !== "2" ? Image1 : Image2}
              className={`${classes.media} ${
                card.id === "3" ? classes.mediaCoaches : classes.mediaIntro
              }`}
            >
              <Box className={classes.content}>
                <Box component="span" className={classes.titleBox}>
                  <Typography
                    className={classes.title}
                    style={{
                      color: `${card.id === "2" ? "black" : "white"}`,
                      fontSize: `${
                        isTabletOrMobileDevice ? "1.5rem" : "1.8rem"
                      }`,
                    }}
                  >
                    {card.title}
                  </Typography>
                </Box>
                <Typography
                  className={classes.description}
                  style={{
                    color: `${card.id === "2" ? "black" : "white"}`,
                    fontSize: `${
                      isTabletOrMobileDevice ? "0.85rem" : "1.0rem"
                    }`,
                  }}
                >
                  {card.description}
                </Typography>
              </Box>
            </CardMedia>
          </CardActionArea>
        </Card>
      </div>
    </Grid>
  );
}
