import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    minWidth: "102px",
    minHeight: "90px",
    height: "12vh",
    borderRadius: 16,
    borderColor: "rgba(251,195,110, 0.6)",
    borderWidth: 1,
    borderStyle: "solid",
    textAlign: "center",
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
  selected: {
    backgroundColor: "#fbc36e",
    color: "white",
  },
  firstItem: {
    height: "49px",
  },
  icon: {
    widht: "100%",
    height: "100%",
  },
  secondItem: {
    height: "7vh",
  },
  title: {
    color: "black",
    height: "100%",
    fontSize: "0.7rem",
    fontWeight: 800,
    textAlign: "center",
    padding: "0px",
    marginTop: "10px",
  },
  titleSelected: {
    color: "white",
  },
}));

// const containerQuery = {
//   widthTopCoachLarger300: {
//     minWidth: 300,
//   },
//   widthTopCoachSmaller300: {
//     maxWidth: 300,
//   },
//   widthTopCoachLarger350: {
//     minWidth: 350,
//   },
//   widthTopCoachSmaller220: {
//     maxWidth: 220,
//   },
//   widthTopCoachLarger410: {
//     minWidth: 410,
//   },
// };

function AnswerBox({ answer, isSelected, callBack }) {
  const classes = useStyles();
  const icon = isSelected ? answer.icon.white : answer.icon.yellow;
  return (
    <Grid item xs={4}>
      <Card className={`${classes.card} ${isSelected && classes.selected}`}>
        <CardActionArea
          onClick={() => {
            callBack(answer);
          }}
        >
          <Grid
            item
            container
            spacing={0}
            align="center"
            justify="flex-end"
            direction="row"
          >
            <Grid item xs={12} className={classes.firstItem}>
              <img
                alt={answer.id}
                src={icon}
                style={{ width: "42px", margin: "7px" }}
              />
            </Grid>
            <Grid item xs={12} className={classes.secondItem}>
              <Typography
                className={`${classes.title} ${
                  isSelected && classes.titleSelected
                }`}
                align="center"
              >
                {answer.text}
              </Typography>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
export default AnswerBox;
