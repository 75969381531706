import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: "white",
    position: "absolute",
    bottom: 0,
    height: "85%",
    border: "1px solid lightgray",
    borderRadius: 15,
    "&.MuiContainer-maxWidthMd": {
      maxWidth: 1000,
    },
  },
}));

export default function SliderBox({ children }) {
  const classes = useStyles();

  return <Container className={classes.content}>{children}</Container>;
}
