import Image1 from "../assets/images/intro111.png";
import Image2 from "../assets/images/intro2.png";
import Image3 from "../assets/images/intro1.png";

export const introData = [
  {
    id: "1",
    title: "Deinen persönlichen Coach finden",
    description:
      "Und gemeinsam an meinen Themen und meiner Zufriedenheit arbeiten.",
    icon: Image1,
    path: "/coachfinder",
  },
  {
    id: "2",
    title: "Diesen Schritt überspringen und direkt zu den Coaches",
    description:
      "Von Stressbewältigung über Konfliktmanagement Zufriedenheit und Co.",
    icon: Image2,
    path: "/coaches",
  },
];

export const finderData = [
  {
    id: "3",
    title: "Finde deinen persönlichen Coach",
    description: "",
    icon: Image3,
    path: "/coachfinder",
  },
];
