import { grey, red } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FBBC42",
      light: "#fbc36e",
    },
    secondary: {
      main: grey[300],
      dark: "black",
    },
    grey: {
      main: "#757575",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#ffffff",
    },
    textSecondary: {
      main: "#595B5C",
    },
  },
  typography: {
    fontFamily: ['"Karla"', "sans-serif"].join(","),
    textTransform: "none",
  },
  overrides: {
    MuiToogleButton: {
      raisedPrimary: {
        fontFamily: ['"Karla"', "sans-serif"].join(","),
      },
    },
  },
});

export default theme;
