import React from "react";

import { makeStyles, Paper } from "@material-ui/core";
import { Box, Typography } from "@material-ui/core";
import MatchBox from "../MatchBox";

const useStyles = makeStyles((theme) => ({
  matchesCard: {
    borderRadius: 16,
    padding: 15,
  },
  matchesTitle: {
    fontSize: "0.9rem",
    fontWeight: 800,
    textAlign: "center",
  },
  matchesDescription: {
    fontSize: "1.0rem",
    color: "black",
    fontWeight: 400,
    textAlign: "center",
  },
}));

const Matches = ({ matches }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.matchesCard} elevation={1}>
      <Typography className={classes.matchesTitle}>
        In diesen Bereichen passt ihr super zueinander
      </Typography>
      <Box marginBottom={1} display="flex" flexDirection="row">
        {matches.map((match) => (
          <MatchBox key={match.id} icon={match.icon.white} title={match.name} />
        ))}
      </Box>
      {/* <Typography className={classes.matchesDescription}>
        Dein ausgewähltes Thema ist außerdem unter Hannas Top 5 Themen
      </Typography> */}
    </Paper>
  );
};

export default Matches;
