import React, { useContext, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// import { useTranslation } from "react-i18next";

import { GlobalContext } from "../../store/contexts/Contexts";
import CoachesList from "../../components/coach/CoachesList";
import TwoSectionsCoachesList from "../../components/coach/TwoSectionsCoachesList";
import WithLoading from "../../hoc/WithLoading";
import IntroCard from "../../components/IntroCard";
import { finderData } from "../../data/IntroData";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Box, Typography, Button } from "@material-ui/core";

import ReactGA from "react-ga4";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useTracking } from "../../utils/tracking";

const ListWithLoading = WithLoading(CoachesList);

const ListWithLoadingTwoSections = WithLoading(TwoSectionsCoachesList);

const useStyles = makeStyles((theme) => ({
  root: {},
  title1: {
    fontSize: "1.5rem",
    fontWeight: 800,
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  rectangle: {
    color: theme.palette.primary.main,
    fontSize: "0.16rem",
    textAlign: "center",
    margin: 10,
  },
  description: {
    fontWeight: 400,
    textAlign: "center",
    fontSize: "0.9rem",
    margin: 20,
  },
  sortButton: {
    color: "#DDDEDE",
    backgroundColor: "white",
    textTransform: "none",
    borderRadius: 20,
  },
}));

export default function Coaches() {
  const { trackPage, trackEvent } = useTracking();
  useEffect(() => {
    trackPage();
  }, [])

  const { coachesState, selectedCoach, setSelectedCoach, selectedAnswers, finishedSurvey } =
    useContext(GlobalContext);

  const history = useHistory();
  const classes = useStyles();
  const params = useParams();

  const ref = useRef();

  const coachSlug = params.coachSlug;

  const didAnswer = finishedSurvey ;

  useEffect(() => {
    setSelectedCoachForCoachSlug(coachSlug);
    // eslint-disable-next-line
  }, [coachSlug]);
  useEffect(() => {
    setSelectedCoachForCoachSlug(coachSlug);
    // eslint-disable-next-line
  }, [coachesState]);

  useEffect(() => {
    ref.current.scrollIntoView();
    // eslint-disable-next-line
  }, []);

  // for bookmarked coach loading:
  const setSelectedCoachForCoachSlug = (coachSlug) => {
    if (!coachesState.coaches) {
      return;
    }
    if (coachSlug) {
      if (!selectedCoach || selectedCoach.slug !== coachSlug) {
        let coach = coachesState.coaches.filter((c) => c.slug === coachSlug);
        coach = coach.length > 0 ? coach[0] : null;
        // setting selected coach from coach-slug in url:
        setSelectedCoach({ coach });
      }
    }
  };

  // const { t } = useTranslation();

  const isTabletDevice = useMediaQuery({
    query: "(max-device-width: 1024px)",
  });

  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });

  const _maxWidth = isMobileDevice ? "100vw" : isTabletDevice ? "90vw" : "70vw";

  // for Responivness of SlideComponent:
  // const isTabletOrMobileDevice = useMediaQuery({
  //   query: "(max-device-width: 768px)",
  // });

  const _data = [
    {
      title: "Lerne unsere Coaches kennen",
      description:
        "Die mentor-Coaches sind alle auf verschiedene Themengebiete und Arbeitsweisen spezialisiert. Starte jetzt unseren Fragebogen und finde heraus, welcher Coach perfekt zu dir und deinen Bedürfnissen passt",
    },
    {
      title: "Meine Coaches",
      description:
        "Diese mentor-Coaches passen aufgrund deiner Antworten am besten zu dir und deinen Themen & Bedürfnissen",
    },
  ];

  const HeaderComponent = ({ data }) => {
    return (
      <Box mt={5}>
        <Typography
          className={classes.title1}
          style={{ color: !isMobileDevice && "black" }}
        >
          {data.title}
        </Typography>
        {!isMobileDevice && (
          <Typography className={classes.rectangle}>
            🟨 🟨 🟨 🟨 🟨 🟨
          </Typography>
        )}
        <Typography className={classes.description}>
          {data.description}
        </Typography>
      </Box>
    );
  };

  const MobileComponent = () => {
    return (
      <Box>
        <Box m={2} mt={4}>
          <IntroCard
            key="0"
            card={finderData[0]}
            onClick={() => history.push("/coachfinder")}
          />
        </Box>
        <Typography
          className={classes.title1}
          style={{
            fontSize: "1.15rem",
          }}
        >
          Lerne unsere Coaches kennen:
        </Typography>
      </Box>
    );
  };

  const CoachesComponent = () => {
    return (
      <Box>
        {isMobileDevice ? (
          <MobileComponent />
        ) : (
          <HeaderComponent data={_data[0]} />
        )}
        <Box mt={3}>
          <ListWithLoading
            isLoading={coachesState.loading}
            repos={coachesState.coaches}
            onCoachSelect={(coach, type,idx) => {trackEvent({ category: 'coaches',
                                                      action:   'coach-select',
                                                      name:     type,
                                                      value: idx,
                                                      customDimenstions: [ {id: "coach", value: coach.slug}]
                                                  })}}
          />
        </Box>
      </Box>
    );
  };

  const SortedCoachesComponent = () => {
    return (
      <Box>
        <HeaderComponent data={_data[1]} />
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          mb={5}
          mt={-1}
        >
          <Button
            variant={isMobileDevice ? "text" : "contained"}
            className={classes.sortButton}
            style={{ fontSize: isMobileDevice ? "0.9rem" : "0.7rem" }}
            onClick={() => {
                              history.push("/coachfinder")
                              trackEvent({ category: 'coaches',
                                            action:   'back-to-survey'
                                          })
                            }}
          >
            Zurück zum Fragebogen
          </Button>
        </Box>
        <Box mt={3}>
          <ListWithLoadingTwoSections
            isLoading={coachesState.loading}
            repos={coachesState.coaches}
            onCoachSelect={(coach, type,idx) => {trackEvent({ category: 'coaches',
                                                    action:   'coach-select',
                                                    name:     type,
                                                    value: idx,
                                                    customDimenstions: [ {id: "coach", value: coach.slug}]
                                                  })}}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Container
      style={{
        maxWidth: _maxWidth,
      }}
    >
      <span ref={ref}></span>
      <Box zIndex="tooltip">
        {didAnswer ? (
          // **** answer mode:
          <SortedCoachesComponent />
        ) : (
          // **** no answer mode:
          <CoachesComponent />
        )}
      </Box>
    </Container>
  );
}
