import React, { useContext, useEffect } from "react";

// import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { GlobalContext } from "../../store/contexts/Contexts";
import CoachesList from "../../components/coach/CoachesList";
import WithLoading from "../../hoc/WithLoading";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Box, Button, Typography } from "@material-ui/core";
import { useTracking } from "../../utils/tracking";

const ListWithLoading = WithLoading(CoachesList);

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: 60,
    paddingTop: 25,
  },
  description: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: 80,
  },
  button: {
    height: 80,
    width: "100%",
    marginBottom: 30,
  },
}));

export default function Home() {

  const {trackPage} = useTracking();
  useEffect(() => {
    trackPage()
  }, [])

  const history = useHistory();
  // const mounted = useRef(false);
  const classes = useStyles();
  // const { t } = useTranslation();
  // const isMounted = useIsMounted();

  const { coachesData, loading } = useContext(GlobalContext);

  return (
    <Container maxwidth="lg">
      <Typography className={classes.title} variant="body1" component="p">
        Hi Sophia
      </Typography>
      <Typography className={classes.description} variant="h6" component="h2">
        Willkommen zu deiner Übersicht:
      </Typography>
      <Box className={classes.button}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => {
            history.push("/coachcoachfinderfinder");
          }}
        >
          Find Coach
        </Button>
      </Box>
      <ListWithLoading isLoading={loading} repos={coachesData} />
    </Container>
  );
}
