import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter } from "react-router-dom";
import ROUTES, { RenderRoutes } from "./navigation/Router";
import "./i18n.translate";
import theme from "./utils/theme";

import * as serviceWorkerRegistration from "./service-workers/serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import GlobalContextProvider from "./store/contexts/GlobalContextProvider";


import { MatomoProvider, useMatomo } from '@datapunt/matomo-tracker-react';
import { useTracking, initTracking } from "./utils/tracking";

import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from '@mui/lab/AdapterDateFns';
import deLocale from 'date-fns/locale/de';

const instance = initTracking();

const MyApp = () => {
  const { enableLinkTracking } = useMatomo()

  enableLinkTracking()

  return (
    <BrowserRouter>
      <React.StrictMode>
        <LocalizationProvider dateAdapter={DateAdapter} locale={deLocale} >
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalContextProvider>
              <RenderRoutes routes={ROUTES} />
            </GlobalContextProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </React.StrictMode>
    </BrowserRouter>
  )
}




// const history = createBrowserHistory();

ReactDOM.render(
  
  <MatomoProvider value={instance}>
    <MyApp />
  </MatomoProvider>,
  document.getElementById("root")
);

serviceWorkerRegistration.unregister();



reportWebVitals();
