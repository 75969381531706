export const IS_LOADING = "is_loading";

export const SET_ASSISTANT_ID = "set_assistant_id";

export const LOGIN = "login";
export const DID_LOGIN = "did_login";

export const GET_INTERVALS = "get_intervals";
export const DID_GET_INTERVALS = "did_get_intervals";
export const DID_GET_INTERVAL_DATES = "DID_GET_INTERVAL_DATES";
export const DID_GET_INTERVAL_TIMES = "DID_GET_INTERVAL_TIMES";
export const DID_GET_EXTERNAL_FREEBUSY = "DID_GET_EXTERNAL_FREEBUSY";

export const SELECT_DATE = "select_date";
export const SELECT_TIME = "select_time";
// export const USER_INFO = "user_info";
export const CONFIRM_APPOINTMENT = "confirm_appointment";
export const APPOINTMENT_CONFIRMED = "appointment_confirmed";

export const ERROR = "error";

export const appointmentInitialState = {
  loading: false,
  coach: null,
  assistantId: null,
  selectedDate: null,
  selectedTime: null,
  userId: null,
  firstname: null,
  lastname: null,
  email: null,
  notes: null,
  availableDays: [],
  availableTimes: [], // times changed by freebusy
  availableTimesOrig: [], // times in salon api
  availableTimesAsDates: [], // times as Date of selectedDate
  confirmAppointment: false,
  appointmentConfirmed: false,
};

export const AppointmentReducer = (state, action) => {
  const action_ = action && action.type ? action : {};
  action_.payload = action_.payload ? action_.payload : {};
  //console.log("AppointmentReducer", action_);

  const isDST = (d) => {
    let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
    let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(jan, jul) != d.getTimezoneOffset(); 
  }

  const convertToDates = ( timeStrs) => {
    const selectedDate = state.selectedDate ? state.selectedDate : new Date();
    return timeStrs.map(timeStr => convertToDate(selectedDate, timeStr) );
  };

  const convertToDate = (selectedDate = new Date(), timeStr) => {

    const hour = parseInt(timeStr.substring(0,2));
    const minutes = parseInt(timeStr.substring(3,5));
    // depending on sommerzeit/winterzeit - other offset to UTC
    const dstOffset  = isDST(selectedDate) ? 2 : 1;
    selectedDate.setHours(12);
    const result = new Date(selectedDate);

    // as selectedDate is at time 0:00, the utc date is a day before (as germany is+1 or +2hours behind)
    // therefor we need to pinpoint the utc date to the date the user selected (the users 'getDate()' ):
    result.setUTCDate(selectedDate.getDate()); 
    result.setUTCHours(hour-dstOffset);
    result.setUTCMinutes(minutes);
  
    return result;
  };

  const isSameDateAs = (d1,d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    ); 
  }

  const availableTimesMinusFreeBusy = (availableTimesAsDates, freeBusies, selectedDate = new Date()) => {
    
    const date = selectedDate ? selectedDate : new Date();
    const relevantFB = freeBusies.filter(entry => {
       
      const result = isSameDateAs(date,entry.start ) || isSameDateAs(date,entry.end );
      //console.log("filter freeBusy: result:", result,date,entry.start );
      return result;
    });
    
    //console.log("*** time freebusy relevantFB:",relevantFB,selectedDate);
    const availableTimes = availableTimesAsDates.filter(time => {
      return relevantFB.reduce((acc,freebusy) => {

        const DURATION_MINUTES_SESSION = 30;
        const DURATION_MINUTES_BUFFER = 15;

        // starTime has to be 45min before entry time (30min of session + 15min of puffer)
        const startTime = new Date(time.getTime() - (DURATION_MINUTES_SESSION + DURATION_MINUTES_BUFFER * 60*1000));
        const endTime = new Date(time.getTime() + (DURATION_MINUTES_BUFFER * 60*1000));
    
        const result = !(endTime >= freebusy.start && startTime <= freebusy.end);

        //console.log("*** time freebusy:",time, startTime, endTime, freebusy, result);
        return acc  &&  result;
      },true);
    })
    //console.log("**availableTimes", availableTimes);

    // convert to string
    return availableTimes.map( entry => entry.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) );
  }

  switch (action_.type) {
    case IS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_ASSISTANT_ID:
      return {
        ...state,
        assistantId: action_.payload,
      };
    case LOGIN:
      return {
        ...state,
        loading: true,
        availableDays: [],
        availableTimes: [],
        availableTimesOrig: [],
        availableTimesAsDates: [],
      };
    case DID_LOGIN:
      return {
        ...state,
        loading: false,
      };
    case GET_INTERVALS:
      return {
        ...state,
        loading: true,
      };
    case DID_GET_INTERVAL_DATES:
      const days = action_.payload.dates ? action_.payload.dates.map(isodate =>  new Date(isodate)) : [];
      return {
        ...state,
        loading: false,
        availableDays: days,
      };
    case DID_GET_INTERVAL_TIMES:
      const availableTimesAsDates = convertToDates(action_.payload.times);
      return {
        ...state,
        loading: false,
        availableTimesOrig: action_.payload.times,
        availableTimesAsDates: availableTimesAsDates, // correct date-object depending on sommerzeit/winterzeit
        availableTimes : availableTimesMinusFreeBusy(availableTimesAsDates, state.freeBusy, state.selectedDate), // depends on selectedDate, and freebusy if available
        
        
      };
    case DID_GET_EXTERNAL_FREEBUSY:
      const freebusy = action_.payload;
      const freebusyConverted = freebusy.map( entry => {return {end: new Date(entry.end), start: new Date(entry.start)};} )
      const availableTimesWithoutFreeBusy = availableTimesMinusFreeBusy(state.availableTimesAsDates, freebusyConverted, state.selectedDate);
      return {
        ...state,
        loading: false,
        freeBusy : freebusyConverted,
        availableTimes : availableTimesWithoutFreeBusy
      };
    case SELECT_DATE:
      return {
        ...state,
        loading: false,
        coach: action.payload.coach,
        selectedDate: action.payload.date,
        availableTimes : availableTimesMinusFreeBusy(state.availableTimesAsDates, state.freeBusy, action.payload.date) // depends on selectedDate
      };
    case SELECT_TIME:
      return {
        ...state,
        loading: false,
        selectedTime: action.payload,
      };

    case CONFIRM_APPOINTMENT:
      return {
        ...state,
        loading: true,
        confirmAppointment: true,
        appointmentConfirmed: false,
        userId: action.payload.userId,
        firstname: action.payload.firstname,
        lastname: action.payload.lastname,
        email: action.payload.email,
        notes: action.payload.notes,
      };
    case APPOINTMENT_CONFIRMED:
      return appointmentInitialState;

    case ERROR:
      return {
        ...state,
        loading: false,
        confirmAppointment: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
