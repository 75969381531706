import React, { useState } from "react";
import { questions } from "../../data/QuestionsData";
import { SurveyContext } from "./Contexts";

export default function SurveyContextProvider({ children }) {
  const [currentQuestion, setCurrentQuestion] = useState(-1);

  return (
    <SurveyContext.Provider
      value={{
        questions,
        currentQuestion,
        setCurrentQuestion,
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
}
