import Api from "../network/Api";
import axios from "axios";

import {
  POST_LOGIN,
  GET_USER_ME,
  POST_USER_ACF,
} from "../network/ApiConstants";

export const LOCAL_USER_DATA = "localUserData";

async function isUserLoggedIn() {
  return new Promise(function (resolve, reject) {
    const loginData = JSON.parse(window.localStorage.getItem(LOCAL_USER_DATA));

    if (loginData) {
      resolve(loginData);
      // } else if (loginData) {
      //   didGetUserToken(loginData, (data) => {
      //     if (data) {
      //       resolve({ loginData: loginData, userData: data });
      //     }
      //   });
    } else resolve(null);
  });
}

async function login(username, password) {
  return new Promise(function (resolve, reject) {
    Api.post(
      POST_LOGIN,
      {
        username,
        password,
      },
      (loginData) => {
        if (loginData && loginData.token) {
          didGetUserToken(loginData, resolve);
        } else {
          resolve(null);
        }
      },
      (error) => {
        //handle error
        console.warn("UserService.login Error: " + error);
        resolve(null);
      }
    );
  });
}

async function didGetUserToken(loginData, resolve) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + loginData.token;
  loadme().then((userData) => {
    if (userData && userData.id) {
      const data = { ...loginData, ...userData };
      window.localStorage.setItem(LOCAL_USER_DATA, JSON.stringify(data));
      resolve(data);
    } else {
      resolve(null);
    }
  });
}

async function loadme() {
  return new Promise(function (resolve, reject) {
    Api.get(
      GET_USER_ME,
      (data) => {
        resolve(data);
      },
      (error) => {
        //handle error
        console.warn("UserService.loadme Error: " + error);
        resolve(null);
      }
    );
  });
}

async function saveCustomFields(userId, fields) {
  // format fields to bodyFormData:
  //fields {key:value} to fields[key]:value :
  var bodyFormData = new FormData();
  Object.keys(fields).forEach((key) => {
    bodyFormData.append(`fields[${key}]`, fields[key]);
  });

  return new Promise(function (resolve, reject) {
    Api.post(
      POST_USER_ACF + userId,
      bodyFormData,
      (data) => {
        resolve(data);
      },
      (error) => {
        //handle error
        console.warn("Error: " + error);
        reject(error);
      }
    );
  });
}

function logout() {
  window.localStorage.setItem(LOCAL_USER_DATA, null);
  delete axios.defaults.headers.common["Authorization"];
}

const UserService = {
  isUserLoggedIn,
  login,
  loadme,
  saveCustomFields,
  logout,
};

export default UserService;
