import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import ImageService from "../../services/ImageService";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";

import { motion } from "framer-motion";

import Grid from "@material-ui/core/Grid";
import { GlobalContext } from "../../store/contexts/Contexts";
import { Box } from "@material-ui/core";

const spring = {
  type: "spring",
  damping: 25,
  stiffness: 120,
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    minHeight: "260px",
    maxHeight: "400px",
    borderRadius: 16,
  },
  media: {
    width: "100%",
    height: "16vw",
    minHeight: "230px",
    maxHeight: "340px",
  },
  bottomBox: {
    paddingTop: "4px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  name: {
    fontWeight: 800,
    textAlign: "center",
    flexGrow: 1,
  },
  field: {
    textAlign: "center",
    fontWeight: 400,
    flexGrow: 2,
    marginTop: -5,
  },
});

function withAnimation(component, _key) {
  // workaorund for motion.div animation to animate sorting of Grid-items: Grid-item-classes are added to motion.div:
  return (
    <motion.div
      className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-6 MuiGrid-grid-sm-3 MuiGrid-grid-lg-3"
      style={{display: "flex", alignSelf: "stretch"}}
      key={_key}
      layout
      transition={spring}
    >
      {component}
    </motion.div>
  );
}

function withoutAnimation(component) {
  return (
    <Grid item xs={6} sm={4} lg={3} style={{display: "flex", alignSelf: "stretch"}}>
      {component}
    </Grid>
  );
}

function Coach({ coach, _key, animate, onCoachSelect }) {
  const { coachesState } = useContext(GlobalContext);
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 768px)",
  });

  const history = useHistory();
  const classes = useStyles();
  const mounted = useRef(false);

  const [media, setMedia] = useState({
    loading: true,
    image: null,
  });

  const getMedia = () => {
    setMedia({ loading: true, image: null });
    ImageService.getMediaWithId(coach["featured_media"], (imageUrl) => {
      if (mounted.current) {
        setMedia({ loading: false, image: imageUrl });
      }
    });
  };

  useEffect(() => {
    getMedia();

    mounted.current = true;

    return function cleanup() {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getMedia();
    // eslint-disable-next-line
  }, [coachesState]);

  const didSelectCoach = () => {
    history.push({
      pathname: "/coaches/" + coach.slug,
      state: coach,
    });
    if (onCoachSelect) onCoachSelect(coach)
  };

  const coachSpeciality = coach && coach.acf && coach.acf.speciality ? coach.acf.speciality : null;

  const coachcard = (
    <Card className={classes.root}>
      <CardActionArea onClick={() => didSelectCoach()}>
        {media.loading ? (
          <Skeleton animation="wave" variant="rect" className={classes.media} />
        ) : (
          <Box style={{position: "relative"}}>

            <Box className={classes.bottomBox} 
                  style={{
                    positon: "relative", 
                    overflow: "hidden",
                    
                  }}>

                <Typography
                  color="primary"
                  className={classes.name}
                  style={{
                    fontSize: `0.5rem`,
                    lineHeight: '0.1rem',
                    color: "#ccc",
                    paddingTop: 5,
                  }}
                >
                  Expertise in/als
                </Typography>
                <Typography
                  color="primary"
                  className={classes.name}
                  style={{
                    fontSize: `${isTabletOrMobileDevice ? "0.7rem" : "0.9rem"}`,
                    
                  }}
                  >
                  {coachSpeciality}
                </Typography>
              

            </Box>
            <CardMedia
              className={classes.media}
              image={media.image}
              component="img"
              alt="image"
            />
           
          </Box>
        )}
        
        <Box className={classes.bottomBox}>
          <Typography
            color="primary"
            className={classes.name}
            style={{
              fontSize: `${isTabletOrMobileDevice ? "0.9rem" : "1.1rem"}`,
            }}
          >
            {coach.title.rendered}
          </Typography>
          {coach.fieldScoreNorm > 0 && (
            <Typography
              className={classes.field}
              style={{
                fontSize: `0.5rem`,
                    fontWeight: 800,
                    color: "#ccc"
              }}
            >
              {(Math.floor(coach.fieldScoreNorm * 100) * 100) / 100 +
                "% Übereinstimmung"}
            </Typography>
          )}
          
        </Box>
        
      </CardActionArea>
    </Card>
  );
  return animate ? withAnimation(coachcard, _key) : withoutAnimation(coachcard);
}

export default Coach;
