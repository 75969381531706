import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core";

import { GlobalContext } from "../../store/contexts/Contexts";
import { useTracking } from "../../utils/tracking";

const useStyles = makeStyles((theme) => ({
  iframe: {
    width: "100%",
    height: "calc(100vh - 69px)",
  },
}));

export default function IFrameCoachDetail({ coachSlug }) {
  const classes = useStyles();

  const [url, setUrl] = useState(
    "https://www.mentor-app.de/find/coach/" +
      coachSlug +
      "/?coach_overview=noshow"
  );

  const { coachesState } = useContext(GlobalContext);

  //if (coachesState.coaches) //console.log("coachesState.coaches.length",coachesState.coaches.length);

  const {trackPage} = useTracking();

  useEffect(() => {
    if (coachesState.coaches) {
      //console.log("iframe coach detail coachSlug:",coachSlug)
      let coach = coachesState.coaches.filter(
        (coach) => coach.slug === coachSlug
      );
      coach = coach.length > 0 ? coach[0] : null;
      //console.log("iframe coach detail coach:",coach);

      setUrl(
        "https://www.mentor-app.de/find/coach/" +
          coach.slug +
          "/?coach_overview=noshow"
      );
      trackPage();
      //console.log("iframe coach detail url1:",url)
    }

    // eslint-disable-next-line
  }, [coachesState.coaches]);

  //console.log("router iframe detail url2:",url)

  return (
    <div>
      <iframe className={classes.iframe} title="Mentor" src={url} />
    </div>
  );
}
