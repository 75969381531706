import { SUBJECT } from "../../utils/helpers";

import { TYPE_ONE, TYPE_TWO, TYPE_THREE } from "../../data/QuestionsData";
import { checkValidAnswer } from "./SchemaAnswers";
import { useParams } from 'react-router-dom';

export const GET_COACHES = "GET_COACHES";
export const SET_COACHES = "SET_COACHES";
export const SET_PERSONAL_SORTED = "SET_PERSONAL_SORTED";
export const SORT_COACHES = "SORT_COACHES";



export const coachesInitialState = {
  coaches: null,
  loading: false,
  personalSorted: null,
};

export function getCoachFinderAnswersFilterActionObject(answers) {
  return {
    type: SORT_COACHES,
    payload: {
      type: null, // type is for dropdown sorting
      personalAnswers: answers, // personalAnswers is for cf-answers sorting
      personalSorting: true,
      subject: null,
      focus: null,
    },
  };
}

export const CoachesReducer = (state, action) => {
  
  var searchParams = new URLSearchParams(window.location.search);
  const showDemoCoaches = searchParams.get("demo");

  const action_ = action && action.type ? action : {};
  //console.log("CoachesReducer1",)
  switch (action_.type) {
    case GET_COACHES:
      return {
        ...state,
        loading: true,
      };
    case SET_COACHES:
      return {
        ...state,
        coaches: showDemoCoaches ? action_.payload : action_.payload.filter(coach => coach.acf.democoach === "nein"),
        loading: false,
      };
    case SET_PERSONAL_SORTED:
      return {
        ...state,
        personalSorted: action_.payload,
      };
    case SORT_COACHES:
      const coaches = sortCoaches(state.coaches, action_.payload);
      console.log("SORT_COACHES - sortCoaches", action_.payload, coaches);
      return {
        ...state,
        coaches: coaches,
      };
    default:
      return state;
  }
};

const sortCoaches = (coaches, filters) => {
  if (!filters) return null;
  if (filters.personalSorting && filters.personalAnswers != null) {
    return sortBasedOnAnswers(coaches, filters.personalAnswers);
  } else {
    const filter = filters.subject != null ? filters.subject : filters.focus;
    // <<<<<<< HEAD

    //     if (filter != null) {
    //       const res = coaches.sort((a, b) => {
    //         let bfield = parseFloat(b["acf"][filter.field]);
    //         let afield = parseFloat(a["acf"][filter.field]);
    //         afield = afield ? afield : 0;
    //         bfield = bfield ? bfield : 0;
    //         // CAUTION: "return a-b;" is not enough, because then:  9-8 is positive == 8 before 9
    //         if (afield > bfield) return -1;
    //         if (afield === bfield) return 0;
    //         if (afield < bfield) return 1;
    //         return 0;
    //       });
    //       //console.log("SORT_COACHES1 - sortCoaches",filter.field, res)
    //       return res;
    // =======
    const maxFieldValue = filters.subject ? 15.0 : 12.0;

    if (filter != null) {
      //console.log("SORT_COACHES1 - filter",filter)
      const res1 = coaches
        .sort((a, b) => {
          let bfield = parseFloat(b["acf"][filter.field]);
          let afield = parseFloat(a["acf"][filter.field]);
          afield = afield ? afield : 0;
          bfield = bfield ? bfield : 0;
          return bfield - afield;
        })
        .map((c) => {
          c.fieldScore =
            ~~((c["acf"][filter.field] / maxFieldValue) * 100) / 100;
          return c;
        });
      const { min: minScore, max: maxScore } = minMaxFieldScore(res1);
      const res2 = res1.map((coach) =>
        normalizeFieldScore(coach, minScore, maxScore)
      );
      //console.log("SORT_COACHES1 - res",res2)
      return res2;
      // >>>>>>> cd-login
    } else {
      return coaches;
    }
  }
};

function minMaxFieldScore(calcCoachesScore1) {
  return calcCoachesScore1.reduce(
    (acc, coach) => {
      return {
        min: (acc.min =
          acc.min < coach.fieldScore ? acc.min : coach.fieldScore),
        max: (acc.max =
          acc.max > coach.fieldScore ? acc.max : coach.fieldScore),
      };
    },
    { min: 0, max: 0 }
  );
}

/* Scale a value from one range to another
 * Example of use:
 *
 * // Convert 33 from a 0-100 range to a 0-65535 range
 * var n = scaleValue(33, [0,100], [0,65535]);
 *
 * // Ranges don't have to be positive
 * var n = scaleValue(0, [-50,+50], [0,65535]);
 *
 * Ranges are defined as arrays of two values, inclusive
 *
 * The ~~ trick on return value does the equivalent of Math.floor, just faster.
 *
 */
function scaleValue(value, from, to) {
  var scale = (to[1] - to[0]) / (from[1] - from[0]);
  var capped = Math.min(from[1], Math.max(from[0], value)) - from[0];
  return ~~(100 * (capped * scale + to[0])) / 100;
}
/**
 * normalizing to a targetRange
 * @param {*} coach with coach.fieldScore
 * @param {*} minScore current filter-coaches min fieldScore
 * @param {*} maxScore current filter-coaches max fieldScore
 * @param {*} targetRange the range to map min max values to (default : [0.05,0.98] )
 */
const normalizeFieldScore = (
  coach,
  minScore,
  maxScore,
  targetRange = [0.1, 1.0]
) => {
  coach.fieldScoreNorm = scaleValue(
    coach.fieldScore,
    [minScore, maxScore],
    targetRange
  );
  return coach;
};

const sortBasedOnAnswers = (coaches, personalAnswers) => {
  // const max_coachfinder_approach = 12;

  // const max_score = 7.0;
  // const max_weight_perSortField = 5;

  //Here we calculate every coaches score based on
  //user input - answers

  //console.log("sortBasedOnAnswers personalAnswers:", personalAnswers)
  const calcCoachesScore1 = coaches.map((coach) => {
    const coachAcf = coach["acf"];

    const scoresByFields = [];
    let coachScoreForAnswers = 0.0;
    
    personalAnswers.forEach((item) => {
      const answerCheck = checkValidAnswer(item);
      if (answerCheck.error) {
        console.error("sortBasedOnAnswers invalid answers", answerCheck);
        return;
      }

      const max_coachfinder_fieldtype_value = item.question.fieldtype === SUBJECT ? 15 : 12;
      //const fieldname = item.question.type === TYPE_ONE ? item.question.field : item.answer.field

      const fieldnames = [];

      switch (item.question.type) {
        case TYPE_ONE:
          fieldnames.push(item.question.field);
          break;
        case TYPE_TWO:
          fieldnames.push(item.answer.field);
          break;
        case TYPE_THREE:
          //fieldnames.push(item.question.field);
          item.answer.forEach((ans) => fieldnames.push(ans.field));
          break;
        default:
      }

      function scoreByField(fieldname, answerWeight) {
        const coachFieldScore = coachAcf[fieldname];
        if (coachFieldScore != null) {
          const fieldWeight = coachFieldScore / max_coachfinder_fieldtype_value; // if there are 10 questions of this field type, the score counts 0.1 * coachFieldScore
          const fieldCalcBasedOnUserInput = fieldWeight * answerWeight; // if the user weighted with 0.25 (of 1), this field counts 0.25 * fieldWeight
          //if (fieldCalcBasedOnUserInput > 0 ) console.log("   score for coach,field: ", coach.slug, fieldname, fieldWeight, " score:", fieldCalcBasedOnUserInput)
          return fieldCalcBasedOnUserInput;
        }
        return 0;
      }

      const scoresByField = fieldnames.map( field => {

        // question with multiple answer (array): only use answer if value is set (.value > 0 ? item.answerWeight, else: 0  )
        const getAnswerWeight = (field, item) => {

          let valueOfAnswer = 0;
          if (Array.isArray(item.answer)){
            valueOfAnswer= item.answer.find(a => a.field === field).value
          }else{
            valueOfAnswer = item.answer.value
          }
          if (valueOfAnswer > 0){
            return item.answerWeight;
          }else{
            return 0; // return zero if no positive value!
          }
        }
        const answerWeight =  getAnswerWeight(field, item);
        return {name:field, score:scoreByField(field, answerWeight)}
      } );
      scoresByFields.push(...scoresByField)
      coachScoreForAnswers += scoresByField.reduce((acc, curr) => acc + curr.score, 0);

    });

    coachScoreForAnswers = Math.floor(coachScoreForAnswers * 100) / 100;
    //console.log("Score: ", coachScoreForAnswers);
    // //console.log(coachScoreForAnswers);
    coach.fieldScore = coachScoreForAnswers;
    coach.fieldScoresSorted = scoresByFields.sort((fielda, fieldb) =>
      fielda.score > fieldb.score ? -1 : 1
    );

    return coach;
  });
  const { min: minScore, max: maxScore } = minMaxFieldScore(calcCoachesScore1);
  const calcCoachesScore2 = calcCoachesScore1.map((coach) =>
    normalizeFieldScore(coach, minScore, maxScore, [0.05, 0.9])
  );

  //console.log(calcCoachesScore2.map(c => c.fieldScoreNorm))

  // sort the coaches
  const sortedCoaches = sortDescWithField(calcCoachesScore2, "fieldScore");

  return sortedCoaches;
};

const sortDescWithField = (array, field) => {
  return array.sort((a, b) => {
    let bfield = parseFloat(b[field]);
    let afield = parseFloat(a[field]);
    afield = afield ? afield : 0;
    bfield = bfield ? bfield : 0;

    // CAUTION: "return a-b;" is not enough, because then:  9-8 is positive == 8 before 9
    if (afield > bfield) return -1;
    if (afield === bfield) return 0;
    if (afield < bfield) return 1;
    return 0;
  });
};
