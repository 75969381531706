import React, { useContext, useEffect } from "react";

import { GlobalContext } from "../../store/contexts/Contexts";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router";
import { SELECT_TIME } from "../../store/reducers/AppointmentReducer";
import { useTracking } from "../../utils/tracking";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 40,
    maxWidth: 600,
  },
  title: {
    widht: "100%",
    fontSize: "1.2rem",
    fontWeight: 500,
    textAlign: "left",
  },
  timeBtn: {
    width: "100%",
    height: 50,
    fontSize: "1.2rem",
    color: theme.palette.primary.main,
  },
}));

export default function TimeSelection() {
  const { appointmentState, appointmentDispatch } = useContext(GlobalContext);

  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    //console.log(appointmentState);

    // eslint-disable-next-line
  }, []);


  const {trackPage} = useTracking();
  useEffect(() => {
    trackPage()
  }, [])

  const didSelectTime = (time) => {
    //console.log(time);
    appointmentDispatch({ type: SELECT_TIME, payload: time });
    history.push("/appointment-info");

  };
  const localeStr = appointmentState.selectedDate ? "Du hast den " +  appointmentState.selectedDate.toLocaleDateString() + "  gewählt." : "Noch kein Datum gewählt...";
  const questionStr = appointmentState.selectedDate ? "Welche Zeit passt dir am Besten? " : "";
  //const dateStr = localeStr.substring(0,localeStr.indexOf(","));

  function TimeButton({ text }) {
    return (
      <Grid item xs={12}>
        <Button
          variant="outlined"
          className={classes.timeBtn}
          onClick={() => didSelectTime(text)}
        >
          {text}
        </Button>
      </Grid>
    );
  }

  return (
    <Container className={classes.root}>
      <Grid container spacing={2} className={classes.content}>
        <Grid item xs={12}>
          <Typography className={classes.title}>
           {localeStr}<br/> {questionStr}
          </Typography>
        </Grid>

        {appointmentState.availableTimes.map((time, index) => {
          return <TimeButton key={index} text={time} />;
        })}
      </Grid>
    </Container>
  );
}
