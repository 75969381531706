import React from "react";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { Avatar, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import { Link } from "react-router-dom";

import LeftArrow from "../../../assets/images/LeftArrow.png";
import RightArrow from "../../../assets/images/RightArrow.png";
import Matches from "./Matches";
import TopSection from "./TopSection";
import SubjectsAccordion from "../SubjectsAccordion";
import MTitleAndText from "../MTitleAndText";
import SwipableTestimonials from "../desktop-version/SwipableTestimonials";
import { isArray } from "util";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    paddingTop: 20,
  },
  nextPreviousBox: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 16,
    height: 60,
    backgroundColor: "#DDDEDE",
    marginBottom: 10,
  },
  previousBtn: {
    width: "25%",
    color: "white",
    borderRight: 1,
    borderStyle: "solid",
  },
  nextBtn: {
    width: "25%",
    color: "white",
    borderLeft: 1,
    borderStyle: "solid",
  },
  middleTxt: {
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: "center",
    fontSize: "1.2rem",
    fontWeight: 800,
    color: "white",
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 800,
    fontSize: "0.9rem",
    marginTop: 10,
    marginBottom: 10,
  },
  terminBtn: {
    width: "60%",
    color: "white",
    fontSize: "0.9rem",
    fontWeight: 800,
    borderRadius: 16,
    textTransform: "none",
    marginTop: 20,
    marginBottom: 20,
  },
}));

export default function DetailsMobile({
  coach,
  coachmatches,
  changeCoach,
  terminAction,
  didClickVideo,
  naviToOverview,
}) {
  const excerpt = coach.excerpt.rendered.replace(/<[^>]+>/g, "");

  const content = coach.content.rendered.replace(/<[^>]+>/g, "");

  const meineSchwerpunkte = coach.acf.meine_schwerpunkte_liste
    .map((item) => item.meine_schwerpunkte_text)
    .join(", ");

  const classes = useStyles();

  const preventDefault = (event) => event.preventDefault();

  const NextPreviousComponent = () => {
    return (
      <Box className={classes.nextPreviousBox}>
        <Button
          className={classes.previousBtn}
          startIcon={<Avatar src={LeftArrow} />}
          onClick={() => changeCoach(false)}
        ></Button>
        <Box
          width="50%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography className={classes.middleTxt} onClick={naviToOverview}>
            Zurück zur Übersicht
          </Typography>
        </Box>
        <Button
          className={classes.nextBtn}
          endIcon={<Avatar src={RightArrow} />}
          onClick={() => changeCoach(true)}
        ></Button>
      </Box>
    );
  };

  const TerminButton = () => {
    return (
      <Box display="flex" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          className={classes.terminBtn}
          onClick={terminAction}
        >
          Termin vereinbaren
        </Button>
      </Box>
    );
  };

  return (
    <Grid container spacing={4} className={classes.content}>
      <Grid item xs={12}>
        <NextPreviousComponent />
      </Grid>
      <Grid item xs={12}>
        <TopSection
          name={coach.title.rendered}
          imageUrl={coach["featured_media"]}
          percentage={
            coach.fieldScoreNorm && parseInt(coach.fieldScoreNorm * 100, 10)
          }
          terminAction={terminAction}
          hasVideo={coach.acf.video_vimeo}
          didClickVideo={didClickVideo}
          motto={coach.acf.motto}
        />
      </Grid>
      <Grid item xs={12}>
        {coachmatches && coachmatches.length > 0 && (
          <Matches matches={coachmatches} />
        )}
      </Grid>
      <Grid item xs={12}>
        <MTitleAndText title="Was mir wichtig ist:" text={excerpt} />
      </Grid>
      {meineSchwerpunkte && (
        <Grid item xs={12}>
          <MTitleAndText title="Meine Schwerpunkte" text={meineSchwerpunkte} />
        </Grid>
      )}
      {coach.acf.lerne_mich_kennen_wysiwg && (
        <Grid item xs={12}>
          <MTitleAndText
            title="Lerne mich besser kennen"
            text={coach.acf.lerne_mich_kennen_wysiwg}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <SubjectsAccordion
          title={"Meine Top 5 Themen"}
          subjects={coach["coachfinder_subject_sorted"].slice(0, 5)}
        />
      </Grid>
      {coach.acf.meine_erfahrungen_text && (
        <Grid item xs={12}>
          <MTitleAndText
            title="Meine Erfahrungen"
            text={coach.acf.meine_erfahrungen_text}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        {
          coach &&
            coach.acf &&
            coach.acf.testimonials &&
            isArray(coach.acf.testimonials) &&
            coach.acf.testimonials.length > 0 && (
              //  <div style={{display: "flex", justifyContent:"center", flexGrow:1}} >
              <SwipableTestimonials
                showNavigation={false}
                coachname={coach.title.rendered}
                testimonials={coach.acf.testimonials}
              />
            )
          //  </div>
        }
      </Grid>
      {coach.acf.zertifikate_link && (
        <Grid item xs={12}>
          <Link
            key={"link"}
            to=""
            href={coach.acf.zertifikate_link}
            onClick={preventDefault}
            color="primary"
            className={classes.link}
          >
            {">> Hier findest du meine Zertifikate"}
          </Link>
        </Grid>
      )}

      <Grid item xs={12}>
        <TerminButton />
      </Grid>
      {content && (
        <Grid item xs={12}>
          <MTitleAndText title="Mehr über mich:" text={content} />
        </Grid>
      )}
      <Grid item xs={12}>
        <NextPreviousComponent />
      </Grid>
    </Grid>
  );
}
