module.exports = {
  login: 'Anmeldung',
  sign_up: 'Anmelden',
  header_home: 'Header Home German',
  header_login: 'The coach in your pocket.',
  fullname: 'Vollständiger Name',
  forgot_password: 'Passwort vergessen',
  reset_password: 'Passwort zurücksetzen',
  username_email: 'Benutzername / E-Mail',
  new_mentor_app: 'Neu in der Mentor App?',
  Email: 'E-Mail',
  username: 'Nutzername',
  password: 'Passwort',
  birthday: 'Geburtstag',
  meetings: 'Sitzungen',
  dashboard: 'Dashboard',
  my_coach: 'Mein Coach',
  coache: 'Coachees',
  historie: 'Geschichte',
  toolbox: 'Werkzeugkasten',
  organisation: 'Organisation',
  journal: 'Journal',
  activities: 'Aktivitäten',
  loading: 'Wird geladen',
  my_profile: 'Mein Profil',
  settings: 'Einstellungen',
  log_out: 'Ausloggen',
  welcome: 'Willkommen',
  start_session: 'Sitzung starten',
};
