import React from "react";
import { useMediaQuery } from "react-responsive";

import { List, makeStyles } from "@material-ui/core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  accordion: {
    backgroundColor: "white",
    borderRadius: 16,
  },
  accordionSummary: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 16,
    paddingTop: 0,
    paddingBottom: 0,
  },
  accordionTitle: {
    width: "100%",
    color: "white",
    fontWeight: 800,
  },
  iconBox1: {
    width: 44,
    height: 44,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  subjectIcon1: {
    width: 30,
    height: 30,
    display: "flex",
    alignItems: "center",
  },
  iconBox2: {
    width: 44,
    height: 44,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  subjectIcon2: {
    width: 38,
    height: 38,
    display: "flex",
    alignItems: "center",
  },
  subjectTitle: {
    color: "black",
    fontWeight: 700,
    fontSize: "0.9rem",
  },
}));

const SubjectsAccordion = ({
  title,
  subjects,
  expanded = false,
  iconStyle = ICON_STYLE_1,
}) => {
  const classes = useStyles();

  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });

  const IconStyle1 = ({ subject }) => {
    return (
      <Box className={classes.iconBox1}>
        <img
          alt={subject.id}
          src={subject.icon.white}
          className={classes.subjectIcon1}
        />
      </Box>
    );
  };

  const IconStyle2 = ({ subject }) => {
    return (
      <Box className={classes.iconBox2}>
        <img
          alt={subject.id}
          src={subject.icon.yellow}
          className={classes.subjectIcon2}
        />
      </Box>
    );
  };

  const IconBox = ({ subject }) => {
    switch (iconStyle) {
      case ICON_STYLE_2:
        return <IconStyle2 subject={subject} />;
      default:
        return <IconStyle1 subject={subject} />;
    }
  };

  return (
    <Accordion
      className={classes.accordion}
      square={true}
      defaultExpanded={expanded}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        style={{ height: isMobileDevice ? 60 : 20 }}
        expandIcon={
          <ExpandMoreIcon
            style={{
              color: "white",
              transform: "scale(1.8)",
            }}
          />
        }
      >
        <Typography
          className={classes.accordionTitle}
          style={{ fontSize: isMobileDevice ? "1.1rem" : "0.8rem" }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <List>
          {subjects.map((subject) => (
            <ListItem key={subject.id}>
              <ListItemIcon>
                <IconBox subject={subject} />
              </ListItemIcon>
              <ListItemText
                primary={subject.name}
                classes={{ primary: classes.subjectTitle }}
              />
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
      <Divider />
    </Accordion>
  );
};

export default SubjectsAccordion;
export const ICON_STYLE_1 = "ICON_STYLE_1";
export const ICON_STYLE_2 = "ICON_STYLE_2";
