import React, { useContext, useEffect, useState } from "react";

import ImageService from "../../services/ImageService";
import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Divider } from "@material-ui/core";
import { appointmentStatus } from "../../services/SalonService";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const useStyles = makeStyles((theme) => ({
  root: {},
  mediaBox: {
    // margin: 10,

    borderRadius: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  image: {
    height: "100%",
    borderRadius: 16,
  },
  firstText: {
    fontSize: "1.1rem",
    fontWeight: 500,
  },
}));

export default function AppointmentDetailsItem({ booking, coach, showContacts=true }) {

  const [media, setMedia] = useState({
    loading: true,
    image: null,
  });

  const classes = useStyles();

  useEffect(() => {
    console.log("useEffect", coach);
    getMedia();
    // eslint-disable-next-line
  }, [coach]);

  const getMedia = () => {
    console.log("getMedia", coach);
    if (coach) {
      setMedia({ loading: true, image: null });
      console.log(coach["featured_media"]);
      ImageService.getMediaWithId(coach["featured_media"], (imageUrl) => {
        console.log("did get media");
        console.log(imageUrl);
        setMedia({ loading: false, image: imageUrl });
      });
    }
  };



  const firstText = () => {
    const dateStr = booking.date + " " + booking.time;
    const fullDate = new Date(dateStr.replace(/-/g, "/")); // - not working in dates in Safari, replaced with /
    let stringDate = '';
    try{
      stringDate = new Intl.DateTimeFormat("de-DE", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",

      }).format(fullDate);
    }catch(e){
      console.warn("error format date ",fullDate,"-",  e);
    }

    return stringDate;
  };

  const secondText = () => {
    const dateStr = booking.date + " " + booking.time;
    const fullDate = new Date(dateStr.replace(/-/g, "/")); // - not working in dates in Safari, replaced with /
    let stringDate = '';
    try{
        stringDate = new Intl.DateTimeFormat("de-DE", {
        hour: "2-digit",
        minute: "2-digit",
      }).format(fullDate);
    }catch(e){
      console.warn("error format date ",fullDate,"-",  e);
    }
    return "Um " + stringDate + " Uhr";
  };

  const thirdText = () => {
    const coachName = booking.services[0].assistant_name;
    return "Mit " + coachName;
  };

  const CoachImage = () => {
    return (
      <Box className={classes.mediaBox}>
        {media.loading ? (
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.image}
            style={{ height: "100%" }}
          />
        ) : (
            <div style={{
              display: "inline-block",
              width: "100px",
              height: "100px",
              background: "url(" + media.image + ")",
              backgroundPosition: "50% 20%",
              backgroundSize: "150px",
              borderRadius: "50%",
              borderColor: "#FBBC42",
              borderStyle: "solid",
            }}>

            </div>
          )}
      </Box>
    );
  };

  

  return (
    <div><Paper elevation={2} style={{ borderRadius: "20px" }}>
      <Box display="flex" flexDirection="row" >
        <Box sx={{ m: 2, minHeight: "120px", justifyContent: "center", display: "flex" }}>
          <CoachImage />
        </Box>
        <Box sx={{ m: 2, minHeight: "120px" }} display="flex" flexDirection="column" justifyContent="center" ml={1}>
          <Box display="flex" sx={{ flexDirection: "row", pb: 0.0 }} ><Typography style={{ fontWeight: 600 }}>{firstText()}</Typography></Box>
          <Box display="flex" sx={{ flexDirection: "row", pb: 0.0 }} ><Typography style={{ fontWeight: 600 }}>{secondText()}</Typography></Box>
          <Box display="flex" sx={{ flexDirection: "row", pb: 0 }} ><Typography style={{ fontWeight: 600 }}>{thirdText()}</Typography></Box>
          <Divider style={{ marginTop: 8, marginBottom: 8 }} />
          <Box display="flex" sx={{ ml: -0.5, flexDirection: "row", pb: 0.5, pt: 0.5 }} ><CheckCircleIcon style={{ marginRight: "5px", color: "#1bc51b" }} /><Typography style={{ color: "#1bc51b" }} ><b>{"Status: " + appointmentStatus(booking.status)}</b></Typography></Box>
        </Box>
      </Box>
    </Paper>
     {showContacts && <Paper elevation={2} style={{ borderRadius: "20px", marginTop: 40 }}>
        <Box display="flex" flexDirection="row" >
          
          <Box sx={{ m: 2, minHeight: "120px" }} display="flex" flexDirection="column" justifyContent="center" ml={1}>
            <Box display="flex" sx={{ flexDirection: "row", pb: 0.0 }} >
              <Typography style={{ fontWeight: 600 }}>{"So erreichst du " + booking.services[0].assistant_name + ":"}</Typography>
            </Box>
            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
            <Box display="flex" sx={{ flexDirection: "row", pb: 0.0 }} >
              <Typography style={{ }}>{booking && booking.services && booking.services[0] &&
             <span> Per <b>Zoom Link </b> zum Termin:  <a href={ booking.services[0].coach_zoomlink}>{booking.services[0].coach_zoomlink}</a> </span>}</Typography>
             </Box>
            <Box display="flex" sx={{ flexDirection: "row", pb: 0.0 }} >
              <Typography style={{  }}>{booking && booking.services && booking.services[0] &&
             <span> Per <b>Email</b> für deine Fragen:  <a href={"mailto:" + booking.services[0].assistant_email}>{booking.services[0].assistant_email}</a> </span>}</Typography>
             </Box>
             
            
          </Box>
        </Box>
      </Paper> }
    </div>
  );
}
