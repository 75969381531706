import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { GlobalContext } from "../../store/contexts/Contexts";
import TwoSectionsCoachesList from "../../components/coach/TwoSectionsCoachesList";
import WithLoading from "../../hoc/WithLoading";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Box, Button, Slide, Typography } from "@material-ui/core";
import CoachDetails from "../coach-details/CoachDetails";
import SliderBox from "../../components/SliderBox";
import { useTracking } from "../../utils/tracking";

const ListWithLoading = WithLoading(TwoSectionsCoachesList);

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: "70vw",
  },
  title1: {
    textAlign: "center",
    fontWeight: 800,
    paddingBottom: 20,
  },
  title2: {
    width: "100%",
    height: "30px",
    color: "black",
    fontWeight: 800,
    textAlign: "center",
    paddingTop: 20,
    marginBottom: 20,
  },
  mButton2: {
    width: "100%",
    height: "30px",
    maxWidth: 400,
    color: theme.palette.primary.main,
    fontWeight: 800,
    fontSize: "0.9rem",
    boxShadow:
      "rgba(255, 0, 0, 0.117647) 0px 1px 6px, rgba(255, 0, 0, 0.117647) 0px 1px 4px", //theme.palette.primary.main,
    borderRadius: 16,
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    marginLeft: 5,
  },
  list: {
    marginTop: "2vh",
  },
  slider: {
    backgroundColor: "rgba(152, 152, 152, 0.15)",
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
  },
}));

export default function SortedCoaches() {
  const { coachesState, selectedCoach } = useContext(GlobalContext);

  const {trackPage} = useTracking();
  useEffect(() => {
    trackPage()
  }, [])

  //   const [filters, setFilters] = useState();

  const history = useHistory();
  const classes = useStyles();
  // const { t } = useTranslation();

  const isTabletDevice = useMediaQuery({
    query: "(max-device-width: 1024px)",
  });

  //   const isTabletOrMobileDevice = useMediaQuery({
  //     query: "(max-device-width: 768px)",
  //   });

  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });

  const _maxWidth = isMobileDevice ? "100vw" : isTabletDevice ? "90vw" : "70vw";

  const SlideComponent = () => {
    return (
      <Slide
        direction="up"
        in={selectedCoach != null}
        mountOnEnter
        unmountOnExit
      >
        <Box className={classes.slider} zIndex="modal">
          <SliderBox>
            {selectedCoach != null && (
              <CoachDetails coachData={selectedCoach} />
            )}
            {/* {filters != null && <FiltersList />} */}
          </SliderBox>
        </Box>
      </Slide>
    );
  };

  return (
    // <CoachContext.Provider value={{ selectedCoach, setSelectedCoach }}>
    <Container
      style={{
        maxWidth: _maxWidth,
      }}
    >
      <Box zIndex="tooltip">
        <Typography
          className={classes.title2}
          style={{
            fontSize: `${isMobileDevice ? "1.0rem" : "1.4rem"}`,
          }}
        >
          Meine Coaches
        </Typography>
        <Typography
          className={classes.title1}
          style={{
            fontSize: `${isMobileDevice ? "1.0rem" : "1.4rem"}`,
          }}
        >
          Diese mentor-Coaches passen aufgrund deiner Antworten am besten zu dir
          und deinen Bedürfnissen
        </Typography>
        <div style={{ width: "100%" }}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            marginBottom="20px"
          >
            <Button
              className={classes.mButton2}
              onClick={() => history.push("/coachfinder")}
            >
              Zum Fragebogen
            </Button>
          </Box>
        </div>


        <Box className={classes.list}>
          <ListWithLoading
            isLoading={coachesState.loading}
            repos={coachesState.coaches}
          />
        </Box>
      </Box>
      {isMobileDevice && <SlideComponent />}
    </Container>
    // </CoachContext.Provider>
  );
}
