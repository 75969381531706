import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";

import { Box } from "@material-ui/core";
import IntroIcon from "../../assets/images/intro11.png";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%", 
    minHeight: "100%",
    maxHeight: "400px",
    margin: "auto",
    border: "none",
    borderRadius: 16,
    backgroundColor: "#FBBC42",
  },
  contentBox: {
    minHeight: "100%",
    maxHeight: "400px",
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  title: {
    color: "white",
    fontWeight: 800,
    textAlign: "center",
    margin: 15,
    marginBottom: "1.8vw",
    [theme.breakpoints.down("1300px")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("1800")]: {
      fontSize: "1.3rem",
    },
  },
  media: {
    borderRadius: 16,
    maxWidth: "60%",
    [theme.breakpoints.down("lg")]: {
      marginLeft: "3vw",
    },
    marginBottom: -4,
  },
}));

export default function CoachFinderItem({ card, onClick }) {
  const classes = useStyles();

  return (
    <Grid item xs style={{display: "flex",alignSelf: "stretch"}}> 
      <Card className={classes.card} elevation={2}>
        <CardActionArea onClick={() => onClick(card.id)}>
          <Box className={classes.contentBox}>
            <Typography className={classes.title}>
              Jetzt meinen passenden Coach finden
            </Typography>
            <img alt="dwa" src={IntroIcon} className={classes.media} />
          </Box>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
