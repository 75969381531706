//const defaultMsg = "Schätze dich ein auf einer Skala von 1-5 in den Bereichen:";
import { mIcon, FOCUS, SUBJECT } from "../utils/helpers";

export const TYPE_ONE = "question_of_type_one";
export const TYPE_TWO = "question_of_type_two";
export const TYPE_THREE = "question_of_type_three";

export const _subjects = [
  {
    id: 1,
    value: 5,
    name: "Kommunikation im Team",
    text: "Kommunikation im Team",
    icon: mIcon(SUBJECT, "Kommunikation"),
    field: "coachfinder_subject_communicationteam",
  },
  {
    id: 2,
    value: 5,
    name: "Veränderungen am Arbeitsplatz",
    text: "Veränderungen am Arbeitsplatz",
    icon: mIcon(SUBJECT, "Veraenderung"),
    field: "coachfinder_subject_changemanagement",
  },
  {
    id: 3,
    value: 5,
    name: "Stress & Überlastung",
    text: "Stress & Überlastung",
    icon: mIcon(SUBJECT, "Stress"),
    field: "coachfinder_subject_stress",
  },
  {
    id: 4,
    value: 5,
    name: "Führung",
    text: "Führung",
    icon: mIcon(SUBJECT, "Fuehrung"),
    field: "coachfinder_subject_leadership",
  },
  {
    id: 5,
    value: 5,
    name: "Belästigung",
    text: "Belästigung",
    icon: mIcon(SUBJECT, "Belaestigung"),
    field: "coachfinder_subject_molesting",
  },
  {
    id: 6,
    value: 5,
    name: `Behandlungs${String.fromCharCode(173)}fehler`,
    text: `Behandlungs${String.fromCharCode(173)}fehler`,
    icon: mIcon(SUBJECT, "Behandlungsfehler"),
    field: "coachfinder_subject_treatmenterrors",
  },
  {
    id: 7,
    value: 5,
    name: "Trauer & Verlust",
    text: "Trauer & Verlust",
    icon: mIcon(SUBJECT, "Trauer"),
    field: "coachfinder_subject_griefloss",
  },
  {
    id: 8,
    value: 5,
    name: "Unsicherheiten & Blockaden",
    text: "Unsicherheiten & Blockaden",
    icon: mIcon(SUBJECT, "Unsicherheit"),
    field: "coachfinder_subject_insecurities",
  },
  {
    id: 9,
    value: 5,
    name: "Schlafprobleme",
    text: "Schlafprobleme",
    icon: mIcon(SUBJECT, "Schlaf"),
    field: "coachfinder_subject_sleepproblems",
  },
  {
    id: 10,
    value: 5,
    name: "Angst & Verzweiflung",
    text: "Angst & Verzweiflung",
    icon: mIcon(SUBJECT, "Angst"),
    field: "coachfinder_subject_fearhopelessness",
  },
  {
    id: 11,
    value: 5,
    name: `Sucht-/${String.fromCharCode(173)}Alkohol${String.fromCharCode(173)}probleme`,
    text: `Sucht-/${String.fromCharCode(173)}Alkohol${String.fromCharCode(173)}probleme`,
    icon: mIcon(SUBJECT, "Sucht"),
    field: "coachfinder_subject_addiction",
  },
  {
    id: 12,
    value: 5,
    name: "Innere Neuausrichtung",
    text: "Innere Neuausrichtung",
    icon: mIcon(SUBJECT, "Neuausrichtung"),
    field: "coachfinder_subject_inneralignment",
  },
  {
    id: 13,
    value: 5,
    name: "Finanzielle Probleme",
    text: "Finanzielle Probleme",
    icon: mIcon(SUBJECT, "Geld_1"),
    field: "coachfinder_subject_financialproblems",
  },
  {
    id: 14,
    value: 5,
    name: "Private Probleme",
    text: "Private Probleme",
    icon: mIcon(SUBJECT, "Private"),
    field: "coachfinder_subject_privateproblems",
  },
  {
    id: 15,
    value: 5,
    name: "Andere...",
    text: "Andere...",
    icon: mIcon(FOCUS, "Berufliche"),
    field: "Andere",
  },

];
export const _approaches = [
  {
    id: 1,
    name: "Zukunftsvision",
    text: "Brauchst du Unterstützung, um eine klare Vision deiner Zukunft zu entwickeln?",
    type: TYPE_ONE, //"Zukunftsvision schaffen",
    field: "coachfinder_approach_futurevision",
    fieldtype: FOCUS,
    icon: mIcon(FOCUS, "Zukunftsvision"),
    answers: [
      { id: 4, value: 3, text: "Ja, ich brauche hier Unterstützung" },
      { id: 3, value: 2, text: "Vielleicht könnte das helfen" },
      { id: 2, value: 1, text: "Ich bin mir nicht sicher" },
      { id: 1, value: 0, text: "Da habe ich schon alles, was ich brauche" },
    ],
  },
  {
    id: 2,
    name: "Fitness",
    text: "Wie wichtig ist dir tägliche Fitness & Bewegung?",
    type: TYPE_ONE, //TYPE_ONE, //"Beschäftigung mit Fitness",
    fieldtype: FOCUS,
    field: "coachfinder_approach_fitness",
    icon: mIcon(FOCUS, "Fitness"),
    answers: [
      { id: 4, value: 3, text: "Sehr wichtig" },
      { id: 3, value: 2, text: "Wichtig" },
      { id: 2, value: 1, text: "Etwas wichtig" },
      { id: 1, value: 0, text: "Unwichtig" },
    ],
  },
  {
    id: 3,
    name: "Ernährung",
    text: "Wie wichtig ist dir eine gesunde & bewusste Ernährung?",
    type: TYPE_ONE, //"Beschäftigung mit Ernährung",
    fieldtype: FOCUS,
    field: "coachfinder_approach_nutrition",
    icon: mIcon(FOCUS, "Ernaehrung"),
    answers: [
      { id: 4, value: 3, text: "Sehr wichtig" },
      { id: 3, value: 2, text: "Wichtig" },
      { id: 2, value: 1, text: "Etwas wichtig" },
      { id: 1, value: 0, text: "Unwichtig" },
    ],
  },
  {
    id: 4,
    name: "Körperarbeit",
    text: "Wie sind deine Erfahrungen mit Körperarbeit?",
    type: TYPE_ONE, //"Körperarbeit hilft mir",
    fieldtype: FOCUS,
    field: "coachfinder_approach_bodywork",
    icon: mIcon(FOCUS, "Koerperarbeit"),
    answers: [
      { id: 4, value: 3, text: "Sehr gute Erfahrungen" },
      { id: 3, value: 2, text: "Kann schon helfen" },
      { id: 2, value: 1, text: "Kenne ich nicht" },
      { id: 1, value: 0, text: "Ist nichts für mich" },
    ],
  },
  {
    id: 5,
    name: "Entspannung",
    text: "Kannst du dich gut entspannen?",
    type: TYPE_ONE, //"Entspannung verbessern",
    fieldtype: FOCUS,
    field: "coachfinder_approach_relaxation",
    icon: mIcon(FOCUS, "Entspannung"),
    answers: [
      { id: 4, value: 3, text: "Nein, leider nicht" },
      { id: 3, value: 2, text: "Manchmal schon" },
      { id: 2, value: 1, text: "Meistens schon" },
      { id: 1, value: 0, text: "Ja, das fällt mir leicht" },
    ],
  },
  {
    id: 6,
    name: "Körper& Geist",
    text: "Beschäftigst du dich mit der Balance zwischen Körper und Geist?",
    type: TYPE_ONE, //"Körper & Geist in Einklang bringen",
    fieldtype: FOCUS,
    field: "coachfinder_approach_mindandbody",
    icon: mIcon(FOCUS, "Geist_Koerper"),
    answers: [
      { id: 4, value: 3, text: "Ja, sie ist das Wichtigste" },
      { id: 3, value: 2, text: "Ja, manchmal schon" },
      { id: 2, value: 1, text: "Nein, nicht so sehr" },
      { id: 1, value: 0, text: "Nein, überhaupt nicht" },
    ],
  },
  {
    id: 7,
    name: "Seele& Gefühlsleben",
    text: "Wie wichtig ist dir Unterstützung im Umgang mit deinen Gefühlen?",
    type: TYPE_ONE, //"Umgang mit meinen Emotionen",
    fieldtype: FOCUS,
    field: "coachfinder_approach_soulandfeelings",
    icon: mIcon(FOCUS, "Seele"),
    answers: [
      { id: 4, value: 3, text: "Das wäre toll" },
      { id: 3, value: 2, text: "Könnte schon helfen" },
      { id: 2, value: 1, text: "Nicht so sehr" },
      { id: 1, value: 0, text: "Brauche ich nicht" },
    ],
  },
  {
    id: 8,
    name: `Gewohnheits${String.fromCharCode(173)}änderungen`,
    text: `Glaubst du, dass dir Gewohnheits${String.fromCharCode(173)}änderungen helfen würden?`,
    type: TYPE_ONE, //"Gewohnheitsänderungen",
    fieldtype: FOCUS,
    field: "coachfinder_approach_habitchange",
    icon: mIcon(FOCUS, "Gewohnheit"),
    answers: [
      { id: 4, value: 3, text: "Ja, dabei benötige ich Hilfe" },
      { id: 3, value: 2, text: "Ja, vielleicht schon" },
      { id: 2, value: 1, text: "Nein, nicht so sehr" },
      {
        id: 1,
        value: 0,
        text: "Nein, meine Gewohnheiten sind super so wie sie sind",
      },
    ],
  },
  {
    id: 9,
    name: `Perspektiv${String.fromCharCode(173)}wechsel`,
    text: "Glaubst du, dass dir neue Perspektiven weiterhelfen können?",
    type: TYPE_ONE, //"Mindset und neue Perspektiven gewinnen",
    fieldtype: FOCUS,
    field: "coachfinder_approach_mindset",
    icon: mIcon(FOCUS, "Mindset_Geist_1"),
    answers: [
      { id: 4, value: 3, text: "Ja, auf jeden Fall!" },
      { id: 3, value: 2, text: "Vielleicht könnte das helfen" },
      { id: 2, value: 1, text: "Ich bin mir nicht sicher" },
      { id: 1, value: 0, text: "Nein, ich möchte an mir nichts ändern" },
    ],
  },
  {
    id: 10,
    name: "Selbstreflexion",
    text: "Reflektierst du regelmässig und gerne über dich selbst?",
    type: TYPE_ONE, //"Selbstreflexion verbessern",
    fieldtype: FOCUS,
    field: "coachfinder_approach_selfreflection",
    icon: mIcon(FOCUS, "Selbstreflexion"),
    answers: [
      { id: 4, value: 3, text: "Ja, das hilft mir auf meinem Weg" },
      { id: 3, value: 2, text: "Vielleicht könnte das helfen" },
      { id: 2, value: 1, text: "Nur wenn es sein muss" },
      { id: 1, value: 0, text: "Nein, das ist nur nutzloses Grübeln" },
    ],
  },
  {
    id: 11,
    name: "Ziele setzen",
    text: "Sind dir konkrete Ziele wichtig für dein Leben?",
    type: TYPE_ONE, //"Ziele setzen",
    fieldtype: FOCUS,
    field: "coachfinder_approach_goals",
    icon: mIcon(FOCUS, "Ziele"),
    answers: [
      { id: 4, value: 3, text: "Ja und dabei hätte ich gerne Unterstützung" },
      {
        id: 3,
        value: 2,
        text: "Ja, aber dabei brauche ich keine Unterstützung",
      },
      { id: 2, value: 1, text: "Ich bin mir nicht sicher" },
      { id: 1, value: 0, text: "Nein, überhaupt nicht" },
    ],
  },
  {
    id: 12,
    name: "Beruflich Orientierung",
    text: "Brauchst du Unterstützung, bei deiner beruflichen (Neu-) Orientierung?",
    type: TYPE_ONE, //"Zukunftsvision schaffen",
    fieldtype: FOCUS,
    field: "coachfinder_approach_jobreorientation",
    icon: mIcon(FOCUS, "Beruflich"),
    answers: [

      { id: 4, value: 3, text: "Ja, ich brauche hier Unterstützung" },
      { id: 3, value: 2, text: "Möglicherweise" },
      { id: 2, value: 1, text: "Ich bin mir nicht sicher" },
      { id: 1, value: 0, text: "Nein, das ist ok, wie es ist." },
    ],
  },
];

export const _subjectsQuest = {
  id: 13,
  name: "Thema",
  text: "Wähle mögliche Themen für dein erstes Coaching:",
  type: TYPE_THREE,
  fieldtype: SUBJECT,
  field: SUBJECT,
  icon: null,
  answers: _subjects,
}

export const _approachesByField = _approaches
  .reduce((acc,app) => {
    acc[app.field] = app;
    return acc;
    },{});


export const questions = [

  ..._approaches,
  _subjectsQuest,

  // {
  //   id: 13,
  //   text: "Wähle ein Thema für dein erstes Coaching:",
  //   type: TYPE_THREE,
  //   field: "coachfinder_approach_fitness",
  //   icon: null,
  //   answers: [
  //     {
  //       id: 1,
  //       value: 1,
  //       text: "Kommunikation im Team",
  //       icon: mIcon(SUBJECT, "Kommunikation"),
  //     },
  //     {
  //       id: 2,
  //       value: 2,
  //       text: "Veränderungen am Arbeitsplatz",
  //       icon: mIcon(SUBJECT, "Veraenderung"),
  //     },
  //     {
  //       id: 3,
  //       value: 3,
  //       text: "Stress & Überlastung",
  //       icon: mIcon(SUBJECT, "Stress"),
  //     },
  //     {
  //       id: 4,
  //       value: 4,
  //       text: "Führung",
  //       icon: mIcon(SUBJECT, "Fuehrung"),
  //     },
  //     {
  //       id: 5,
  //       value: 5,
  //       text: "Belästigung",
  //       icon: mIcon(SUBJECT, "Belaestigung"),
  //     },
  //     {
  //       id: 6,
  //       value: 6,
  //       text: "Behandlungs fehler",
  //       icon: mIcon(SUBJECT, "Behandlungsfehler"),
  //     },
  //     {
  //       id: 7,
  //       value: 7,
  //       text: "Trauer & Verlust",
  //       icon: mIcon(SUBJECT, "Trauer"),
  //     },
  //     {
  //       id: 8,
  //       value: 8,
  //       text: "Unsicherheiten & Blockaden",
  //       icon: mIcon(SUBJECT, "Unsicherheit"),
  //     },
  //     {
  //       id: 9,
  //       value: 9,
  //       text: "Schlafprobleme",
  //       icon: mIcon(SUBJECT, "Schlaf"),
  //     },
  //     {
  //       id: 10,
  //       value: 10,
  //       text: "Angst & Verzweiflung",
  //       icon: mIcon(SUBJECT, "Angst"),
  //     },
  //     {
  //       id: 11,
  //       value: 11,
  //       text: `Sucht-/${String.fromCharCode(173)}Alkoholprobleme`,
  //       icon: mIcon(SUBJECT, "Sucht"),
  //     },
  //     {
  //       id: 12,
  //       value: 12,
  //       text: "Innere Neuausrichtung",
  //       icon: mIcon(SUBJECT, "Neuausrichtung"),
  //     },
  //     {
  //       id: 13,
  //       value: 13,
  //       text: "Finanzielle Probleme",
  //       icon: mIcon(SUBJECT, "Geld_1"),
  //     },
  //     {
  //       id: 14,
  //       value: 14,
  //       text: "Private Probleme",
  //       icon: mIcon(SUBJECT, "Private"),
  //     },
  //     {
  //       id: 15,
  //       value: 15,
  //       text: "Anderes Thema",
  //       icon: mIcon(SUBJECT, "Kinder"),
  //     },
  //   ],
  // },
];

// ["coachfinder_approach_fitness"],
//   ["coachfinder_approach_nutrition"],
//   [
//     "coachfinder_approach_bodywork",
//     "coachfinder_approach_mindandbody",
//     "coachfinder_approach_relaxation",
//   ],
//   [
//     "coachfinder_approach_mindset",
//     "coachfinder_approach_selfreflection",
//     "coachfinder_approach_habitchange",
//   ],
//   ["coachfinder_approach_soulandfeelings"],
//   ["coachfinder_approach_goals", "coachfinder_approach_futurevision"];

//console.log("iconsSubjectYellow",iconsSubjectYellow)
