import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useMediaQuery } from "react-responsive";
import { GlobalContext } from "../store/contexts/Contexts";

import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  ButtonBase,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LoginIcon from "@mui/icons-material/Login";
import { Link, Menu, MenuItem } from "@mui/material";

// const languageData = [
//   {
//     label: "English",
//     value: "en",
//   },
//   {
//     label: "German",
//     value: "de",
//   },
// ];

const useStyles = makeStyles((theme) => ({
  root: {},
  menuButton: {
    marginRight: theme.spacing(2),
    color: "black",
    fontSize: "1.0rem",
    fontWeight: 800,
  },
  langButton: {},
  button: {
    color: theme.palette.grey[400],
    justifyContent: "right",
    height: "100%",
  },
  backBtn: {
    color: theme.palette.grey[400],
    justifyContent: "left",
    height: "100%",
  },
  title: {
    color: "black",
    fontSize: "1.3rem",
    fontWeight: 800,
  },
  toolbar: theme.mixins.toolbar,
}));

export default function Header({ title, onHeaderClick }) {

  const [anchorEl, setAnchorEl] = useState(null);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const { coachesState, usersState, goBack } = useContext(GlobalContext);

  

  const headerLinksUser = [
    {
      label: "Coaches",
      href: getCoachesRef(),
    },
    {
      label: "Coachfinder",
      href: "/coachfinder",
    },
    {
      label: "Meine Termine",
      href: "/my-appointments",
      privateLink: true,
    },
    {
      label: "Login",
      href: "/login",
    },
  ];

  const headerLinksCoach =[
    {
      label: "Meine Termine",
      href: "/my-appointments",
      privateLink: true,
    },
    {
      label: "Neuer Termin",
      href: "/appointment-new",
      privateLink: true,
    },
    {
      label: "Login",
      href: "/login",
    },
  ];

  const [headerLinks, setHeaderLinks] = useState(headerLinksUser);

  const classes = useStyles();
  const history = useHistory();
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1366px)",
  });

  useEffect(() => {
    updateHeaderIfNeeded();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersState]);

  function getCoachesRef() {
    return coachesState.personalSorted ? "sorted-coaches" : "/coaches";
  }

  const updateHeaderIfNeeded = () => {
    

    var headerLinks_ = (usersState.coach !== null ? headerLinksCoach :  headerLinksUser)
        .filter(entry => !entry.privateLink || (entry.privateLink && usersState.loggedIn)) // 
        .map((entry) => {
          if (entry && entry.href && entry.href === "/login") {
            entry.label = usersState.loggedIn ? "LogOut" : "Login";
          }
          
          return entry;
    });
    console.log("headerLinks_", usersState.loggedIn, usersState.coach, headerLinks_, headerLinks);
   
    setHeaderLinks(headerLinks_);
  };

  const getMenuButtons = () => {
    return headerLinks.map(({ label, href }) => {
      return (
          <Button
            {...{
              key: label,
              to: href, // for "RouterLink" component
              href : href, // for "a" component
              component: href.startsWith("http") ? "a" : RouterLink ,
            }}
            className={classes.menuButton}
            onClick={() => {if (onHeaderClick) onHeaderClick(label)} }
          >
            {label}
          </Button>
      );
    });
  };

  // const getLangButtons = () => {
  //   return languageData.map(({ label, value }) => {
  //     return (
  //       <Button
  //         key={value}
  //         className={classes.langButton}
  //         value={value}
  //         onClick={handleLanguageSelection}
  //       >
  //         {label}
  //       </Button>
  //     );
  //   });
  // };

  const renderMobileMenu = () => {
    return (<Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}> 
      {getMenuButtons().map(button => {return (<MenuItem onClick={handleClose}>{button}</MenuItem>)})}
      
      </Menu>);
    
    };

  const mobileHeader = () => {
    return (
      <Box display="flex" p={1} width="100%">
        <Box>
          {history.location.pathname !== "/" && (
            <ButtonBase className={classes.backBtn} onClick={() => goBack()}>
              <ArrowBack />
            </ButtonBase>
          )}
        </Box>
        <Box flexGrow={1}>
          <Link href="/" underline="none"><Typography className={classes.title} align="center">
            {title}
          </Typography></Link>
        </Box>
      
    
        <Box>
          {usersState.loggedIn ? (
           <ListAltIcon onClick={handleProfileMenuOpen} />
          ) : (
            <ButtonBase
              className={classes.button}
              {...{
                key: "login",
                to: "/login",
                component: RouterLink,
              }}
            >
              <LoginIcon />
            </ButtonBase>
          )}
        </Box>
        {renderMobileMenu()}
      </Box>
    );
  };

  const desktopHeader = () => {
    return (
      <Box display="flex" p={1} width="100%">
        <Box flexGrow={1} alignItems="center" display="flex">
          <Link href="/" underline="none"><Typography className={classes.title} align="left">
            {title}
          </Typography></Link>
        </Box>
        <Box>{getMenuButtons()}</Box>
      </Box>
    );
  };

  return (
    <div className={classes.root}>
      <AppBar style={{ backgroundColor: "white" }}>
        <Toolbar>
          {isTabletOrMobileDevice ? mobileHeader() : desktopHeader()}
        </Toolbar>
      </AppBar>
    </div>
  );
}
