import React, { useContext, useEffect, useState } from "react";

import {
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
} from "@material-ui/core";
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemButton,
  MenuItem,
  Skeleton,
} from "@mui/material";
import ImageService from "../../services/ImageService";
import { GlobalContext } from "../../store/contexts/Contexts";

// import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// const useStyles = makeStyles({
//   root: {},
// });

export default function AppointmentItem({
  item,
  isPastBooking,
  actionDetails,
  actionCancelAppointment,
}) {
  const { coachesState } = useContext(GlobalContext);

  const [media, setMedia] = useState({
    loading: true,
    image: null,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const coachName = item.services[0].assistant_name;

  const secondLine = "mit " + item.customer_first_name + ' ' + item.customer_last_name;

  //   const classes = useStyles();

  useEffect(() => {
    getMedia();

    // eslint-disable-next-line
  }, []);

  const getMedia = () => {
    const coach = getCoachWithName(coachName);
    console.log("coach");
    console.log(coach);
    if (coach) {
      setMedia({ loading: true, image: null });
      console.log(coach["featured_media"]);
      ImageService.getMediaWithId(coach["featured_media"], (imageUrl) => {
        console.log("did get media");
        console.log(imageUrl);
        setMedia({ loading: false, image: imageUrl });
      });
    }
  };

  const firstText = () => {
    
    const dateStr = item.date + " " + item.time
    const fullDate = new Date(dateStr.replace(/-/g, "/")); // - not working in dates in Safari, replaced with /
    const stringDate = new Intl.DateTimeFormat("de-DE", {
      //   weekday: "long",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(fullDate);

    return "Do " + stringDate + " Uhr";
  };

  const getCoachWithName = (coachName) => {
    if (!coachesState.coaches) {
      return;
    }
    if (coachName) {
      const findCoach = coachesState.coaches.filter(
        (c) => c.title.rendered === coachName
      );
      const coach = findCoach.length > 0 ? findCoach[0] : null;
      return coach;
    }
  };

  const CoachImage = () => {
    return (
      <Avatar>
        {media.loading ? (
          <Skeleton animation="wave" variant="rect" />
        ) : (
          <img
            src={media.image}
            component="img"
            alt="coachImage"
            style={{ height: "100%" }}
          />
        )}
      </Avatar>
    );
  };

  return (
    <ListItem key={item.id} divider={true}>
      <ListItemButton
        disabled={isPastBooking}
        onClick={isPastBooking ? undefined : () => actionDetails(item)}
      >
        <ListItemAvatar>
          <CoachImage />
        </ListItemAvatar>
        <ListItemText primary={firstText()} secondary={secondLine} />
      </ListItemButton>
      {!isPastBooking && (
        <ListItemSecondaryAction>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls="long-menu"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => actionDetails(item)}>Details</MenuItem>
            <MenuItem onClick={() => actionCancelAppointment(item)}>
              Absagen
            </MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
