import React, { useEffect, useState } from "react";

import ImageService from "../../../services/ImageService";

import Skeleton from "@material-ui/lab/Skeleton";
import { Box, CardMedia, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import PlayCircleOutlineRoundedIcon from "@material-ui/icons/PlayCircleOutlineRounded";

const useStyles = makeStyles((theme) => ({
  mediaBox: {
    width: "100%",
    height: "100%",
    display: "flex",
    position: "relative",
  },
  media: {
    width: "100%",
    maxHeight: 340,
    textAlign: "center",
    borderRadius: 40,
  },
  mediaItemsBox: {
    width: 50,
    height: 50,
    margin: 5,
    borderRadius: 16,
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
  },
}));

function DetailsMedia({ imageUrl, didClickVideo, hasVideo }) {
  const [media, setMedia] = useState({
    loading: true,
    image: null,
  });

  const classes = useStyles();

  const getMedia = () => {
    setMedia({ loading: true, image: null });
    ImageService.getMediaWithId(imageUrl, (imageUrl) => {
      setMedia({ loading: false, image: imageUrl });
    });
  };

  useEffect(() => {
    getMedia();

    // eslint-disable-next-line
  }, [imageUrl]);

  const VideoButton = ({ id }) => {
    return (
      // <Box className={classes.mediaItemsBox}>
      <IconButton
        children={
          <PlayCircleOutlineRoundedIcon
            color="primary"
            style={{ transform: "scale(2.8)" }}
            fontSize="large"
          />
        }
        onClick={() => didClickVideo()}
      />
      // </Box>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="15px"
    >
      <Box className={classes.mediaBox}>
        {media.loading ? (
          <Skeleton animation="wave" variant="rect" className={classes.media} />
        ) : (
          <CardMedia
            className={classes.media}
            image={media.image}
            component="img"
            alt="image"
            display="flex"
            height="100%"
            style={{ minHeight: "400px" }}
          />
        )}
        {hasVideo && (
          <div
            style={{
              position: "absolute",
              opacity: 0.6,
              top: "60%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <VideoButton />
          </div>
        )}
      </Box>
    </Box>
  );
}

export default DetailsMedia;
