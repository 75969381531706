export const PERSONAL_SORT = "PERSONAL_SORT";
export const ANSWER_QUESTION = "ANSWER_QUESTION";
export const SUBJECT_SORT = "SUBJECT_SORT";
export const FOCUS_SORT = "FOCUS_SORT";

export const filterInitialState = {
  personalSorting: false,
  personalAnswers: null,
  subject: null,
  focus: null,
};

export const FilterReducer = (state, action) => {
  switch (action.type) {
    case PERSONAL_SORT:
      return {
        ...state,
        personalSorting: true,
        subject: null,
        focus: null,
      };
    case SUBJECT_SORT:
      return {
        ...state,
        personalSorting: false,
        subject: action.payload,
        focus: null,
      };
    case FOCUS_SORT:
      return {
        ...state,
        personalSorting: false,
        subject: null,
        focus: action.payload,
      };
    case ANSWER_QUESTION:
      return {
        personalAnswers: action.payload,
        personalSorting: true,
        subject: null,
        focus: null,
      };
    default:
      return state;
  }
};
