import React from "react";
import Button from "@material-ui/core/Button";

export const PageNotFound = ({ ...props }) => (
  <div>
    <h1 className="header">Page not found, ERROR: 404</h1>
    <h3 className="header">UH OH! You&rsquo;re lost.</h3>
    <Button onClick={() => props.history.replace("/")}>Go Back</Button>
  </div>
);
