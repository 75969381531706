import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
// import { useMediaQuery } from "react-responsive";

// import { useTranslation } from "react-i18next";
// import WithLoading from "../../hoc/WithLoading";

import { makeStyles } from "@material-ui/core";

import TextField from '@mui/material/TextField';
import DatePicker  from '@mui/lab/DatePicker'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';

import Container from "@material-ui/core/Container";
import { Box, CircularProgress, Typography, Paper, Link } from "@material-ui/core";

import { Grid } from "@mui/material";

import Button from '@material-ui/core/Button';

import SalonService, { appointmentStatus } from "../../services/SalonService";
import { GlobalContext } from "../../store/contexts/Contexts";
import { useTracking } from "../../utils/tracking";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  title1: {
    fontSize: "1.5rem",
    fontWeight: 800,
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  addButton: {
    marginTop: theme.spacing(4),
    color: "white",
    textTransform: "none",
  },
  
}));

export default function NewAppointment()  {


  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();

  var dt = new Date();
  // Date in UTC and ISO format: "2021-11-30T20:33:32.222Z"
  console.log(dt.toISOString());
  var dtOffset = new Date(dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset()));
  // Date in EST and ISO format: "2021-11-30T15:33:32.222Z"
  const stringDate= dtOffset.toISOString().substring(0,16);

  const [value, setValue] = useState(new Date());
  const [minute, setMinute] = useState(0);
  const [hour, setHour] = useState(12);

  const { usersState } = useContext(GlobalContext);
  const [client, setClient] = useState(null);
  const [bookings, setBookings] = useState(null);
  const [emails, setEmails] = useState([]);

  const [errorBooking, setErrorBooking] = useState(false);

  const [bookingEnabled, setBookingEnabled] = useState(12);

  useEffect(() => {
    //console.log("setBookingEnabled" + (client !== null && value !== null && minute !== null && hour  !== null))
    setBookingEnabled(client !== null && value !== null && minute !== null && hour  !== null);
  }, [client,value, hour, minute])

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const addBooking = () => {
    
    if (client) {
      const appointment = { selectedDate : value, 
                            selectedTime : "" + hour + ":" + (minute === 0 ? "00" : minute), 
                            firstname: client.firstname,
                            lastname: client.lastname,
                            email: client.email,
                            userId: client.userId,
                            assistantId : usersState.coach,
                            notes: "" }

      console.log("addBooking client", client, appointment)
      SalonService.bookAppointment(appointment, (data) => {
          if (data){
            setErrorBooking(false);
            history.push("/my-appointments")
          } else {
            setErrorBooking(true);
          }
        })
    }
  }

  useEffect(() => {
    if (usersState.coach > 0) {
      SalonService.getCoachAppointments(usersState.coach, false, (bookingsData) => {
        if (bookingsData) {
          console.log("new appoint bookingsData",bookingsData)
          setBookings(bookingsData);
          const emls = bookingsData.map( (b) => {return { label: b.customer_first_name + ' ' + b.customer_last_name ,  // + ' (' +b.customer_email + ')'
                                                          firstname: b.customer_first_name, 
                                                          lastname:  b.customer_last_name, 
                                                          userId: b.customer_id,
                                                          email: b.customer_email  }} )
                                    .filter((item, pos, self) => self.findIndex(v => v.userId === item.userId) === pos)
          console.log("new appoint ",emls)    
          setEmails(emls);
        }
      });
    }
  }, []);

  return (
    <Container className={classes.root}>

      
      <Box mt={4} mb={4}>
        <Typography className={classes.title1}>Deine Termine</Typography>

        
      </Box>
      <form style={{ textAlign: "center" }} noValidate autoComplete="off">
      <Box maxWidth="600px" minWidth="40vw" minHeight="280px"
        style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between"}}>
        
        
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={emails}
            value={client}
            onChange={(e, val) => setClient(val)}
            sx={{ width: 220 }}
            renderInput={(params) => <TextField {...params} label="Klient" />}
          />

        <DatePicker
          label="Datum"
          mask='__.__.____'
          value={value}
          sx={{ width: 220 }}
          ampm={false}
          minutesStep={30}
          onChange={handleChange}
          minDate={new Date()}
          minTime={new Date(0, 0, 0, 6)}
          maxTime={new Date(0, 0, 0, 22, 0)}
          renderInput={(params) => <TextField {...params}  sx={{ width: 220 }}/>}
        />
        <Box>
          <Select
            labelId="hour-select"
            id="hour-select"
            value={hour}
            sx={{ width: 110 }}
            label="Stunde"
            defaultValue="12"
            onChange={(e) => setHour(e.target.value)}
          >
            {Array.from({length: 17}, (_, i) => i + 6).map(i => <MenuItem value={i}>{i}</MenuItem>)}
            
          </Select>
          <Select
            labelId="minute-select"
            id="minute-select"
            value={minute}
            label="Minute"
            sx={{ width: 110 }}
            onChange={(e) => setMinute(e.target.value)}
          >
            <MenuItem value={0}>00</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={45}>45</MenuItem>
          </Select>
        </Box>
        
        <Button
            variant="contained"
            color="primary"
            className={classes.addButton}
            
            disabled={!bookingEnabled}
            onClick={() => addBooking()}
            >
            Termin anlegen
          </Button>
          {usersState.error &&
          " Die Buchung konnte nicht angelegt werden. Bitte probieren Sie es später noch einmal. "}
          
        </Box>
      </form>
    </Container>
  )
}

