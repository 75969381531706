import React from "react";
import { useMediaQuery } from "react-responsive";

// import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Coach from "./Coach";
import TopCoach from "./TopCoach";
import { Box, Typography } from "@material-ui/core";

export default function TwoSectionsCoachesList({ repos, onCoachSelect }) {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 768px)",
  });

  if (!repos || !repos.length) return <p>No repos, sorry</p>;

  const topThreeCoaches = repos.slice(0, 3);
  const otherCoaches = repos.slice(3);

  return (
    <Grid
      container
      spacing={isTabletOrMobileDevice ? 2 : 3}
      align="center"
      alignItems="center"
    >
      {topThreeCoaches.map((coach,idx) => (
        <TopCoach key={coach.slug} coach={coach} animate={true}  
                  onCoachSelect={() => { if (onCoachSelect) onCoachSelect(coach, "twosections-topcoach",idx) }} />
      ))}
      <Grid item xs={12}>
        <Box marginTop="10px" marginBottom="10px">
          <Typography
            component="h1"
            color="primary"
            style={{ fontSize: "1.5rem", fontWeight: 800 }}
          >
            - - - - - - - - - - - - - - - - -
          </Typography>
        </Box>
      </Grid>
      {otherCoaches.map((coach,idx) => (
        <Coach
          key={coach.slug}
          _key={coach.slug}
          coach={coach}
          animate={true}
          onCoachSelect={() => { if (onCoachSelect) onCoachSelect(coach, "twosections-othercoach",idx) }}
        />
      ))}
    </Grid>
  );
}
