import React, { useContext } from "react";

// import { useTranslation } from "react-i18next";

import { GlobalContext, SurveyContext } from "../../store/contexts/Contexts";

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Typography,
  Tooltip,
  Paper,
  Button,
} from "@material-ui/core";

import { mergeArrays, findOneEntry } from "../../utils/helpers";

import { grey } from "@material-ui/core/colors";

import { TYPE_THREE, TYPE_TWO } from "../../data/QuestionsData";

import { ToggleButton } from "@material-ui/lab";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    width: "100%",
    maxWidth: 400,
    maxHeight: 600,
    margin: "auto",
  },
  finishBox: {
    textAlign: "center",
    paddingTop: "20px",
    bottom: "20px",
  },
  finishBtn: {
    maxWidth: 250,
    color: "white",
    fontSize: "0.9rem",
    fontWeight: 800,
    borderRadius: "1.5vh",
    textTransform: "none",
    marginBottom: "40px",
  },
  arrowsBox: {
    display: "flex",
    width: "100%",
    textAlign: "center",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "15vh",
  },
  arrowBtn: {
    borderColor: grey[200],
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: "1.5vh",
    margin: 5,
    width: 40,
    height: 40,
  },
  disabledButton: {
    color: grey[100],
  },
  bottomText: {
    color: grey[500],
    fontWeight: "bold",
    paddingLeft: 10,
    paddingRight: 10,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,

    marginBottom: theme.spacing(1),
  },
  answerBtn: {
    color: "black", //theme.palette.primary.main,
    backgroundColor: "white",
    fontWeight: 800,
    fontSize: "0.9rem",
    margin: "1px",
    padding: "2px",
    width: "90%",

    borderRadius: "5px",
    borderColor: theme.palette.primary.light,
    borderWidth: 1,
    borderStyle: "solid",
    textTransform: "none",
    justifyContent: "center",
  },
  answerSelected: {
    color: "white",
    backgroundColor: theme.palette.primary.main + "!important",
  },
  multipageBox: {
    textAlign: "center",
    bottom: "0px",
  },
}));

export default function Survey({ saveSurvey, onSelectAnswer, onToggleSurveyMode }) {
  const {
    selectedAnswers,
    setSelectedAnswers,
    finishedSurvey,
    toggleSurveyMode,
    surveymode,
  } = useContext(GlobalContext);
  const { questions } = useContext(SurveyContext);

  const classes = useStyles();

  const handleButton = (evt, answer, question, length) => {
    //console.log("handleButton value:", ans, q, length)

    if (onSelectAnswer) onSelectAnswer(question.field, answer);

    // type three question: set all answers to 0 if not given in answers-array
   


    const answerFields = Array.isArray(answer)
      ? answer.map((ans) => ans.field)
      : [];
    const answerByType = Array.isArray(answer)
      ? question.answers.map((a) => {
          // set value to 0 if not given in answerFields - else 1
          const newVal = answerFields.includes(a.field) ? 1 : 0;
          return { ...a, value: newVal };
        })
      : answer;
    const sAnswer = {
      question: question,
      answer: answerByType,
      // value between 0 and 1
      answerWeight: !Array.isArray(answer)
        ? answer.value / question.answers.length // type one question - 0..4 / 4
        : answer.length > 0
        ? 1 // type three question - every subject gets weight 1
        : 0,
    };
    const newAns = [sAnswer];
    var tempSelectedAnswersArray = mergeArrays(
      selectedAnswers,
      newAns,
      "question",
      "field"
    );
    //console.log("handleButton tempSelectedAnswersArray:", tempSelectedAnswersArray)
    setSelectedAnswers(tempSelectedAnswersArray);
  };

  return (
    <div
      style={{
        paddingTop: "10px",
        backgroundColor: "#white",
        overflowY: "hidden",
      }}
    >
      {questions.map((oneDefQuestion) => {
        const userAnswer = findOneEntry(
          selectedAnswers,
          ["question", "field"],
          oneDefQuestion.field
        );
        const userAnswerObjects = userAnswer ? userAnswer.answer : null;
        const answerFieldNames = Array.isArray(userAnswerObjects)
          ? userAnswerObjects.filter((a) => a.value > 0).map((a) => a.field)
          : []; // only type three is array
        const selectAns = oneDefQuestion.answers.filter((qAns) => {
          if (oneDefQuestion.type === TYPE_THREE) {
            //type three: multiple answerFieldNames
            return answerFieldNames.includes(qAns.field);
          } else {
            //type one: one answer only: value equals value = selected
            return qAns.value === userAnswerObjects.value;
          }
        });

        //console.log("SurveyOnePage List value", oneDefQuestion,userAnswerObjects,oneDefQuestion.answers);

        const fullLineQ = oneDefQuestion.type === TYPE_TWO ? true : false;

        return (
          <Grid container spacing={2} style={{ backgroundColor: "#fff" }}>
            <Grid item xs={12} zeroMinWidth>
              <Paper className={classes.paper}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={fullLineQ ? 12 : 5}
                    md={5}
                    component="div"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography component="div" color="inherit">
                      {oneDefQuestion.text}
                    </Typography>
                  </Grid>
                  <Grid item xs={fullLineQ ? 12 : 7} md={7} component="div">
                    <ToggleButtonGroup
                      orientation="vertical"
                      exclusive={
                        oneDefQuestion.type === TYPE_THREE ? false : true
                      }
                      style={{ width: "100%", display: "block" }}
                      value={selectAns}
                      onChange={(e, v) => handleButton(e, v, oneDefQuestion, 4)}
                    >
                      {oneDefQuestion.answers.map((onePosAnswer) => (
                        <ToggleButton
                          className={`${classes.answerBtn}`}
                          value={onePosAnswer}
                          key={
                            "tb_" + oneDefQuestion.id + "_" + onePosAnswer.id
                          }
                        >
                          <Tooltip title={onePosAnswer.text} placement="top">
                            <Box m={0} component="div" whiteSpace="normal">
                              {onePosAnswer.text}
                            </Box>
                          </Tooltip>
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        );
      })}
      <Grid item xs={12} zeroMinWidth>
        {surveymode === "onepage" && (
          <Box className={classes.finishBox}>
            <Button
              className={classes.finishBtn}
              variant="contained"
              color="primary"
              onClick={() => saveSurvey(true, true)}
            >
              Meinen Coach finden
            </Button>
            {/* <Button
                  onClick={() => toggleSurveyMode()}
                >
                  {(surveymode === "onepage" ) && (<ViewStreamIcon color="primary"/>) }
                </Button> */}
          </Box>
        )}
        {finishedSurvey && (
          <Box className={classes.multipageBox}>
            <Button onClick={() => {toggleSurveyMode(); if (onToggleSurveyMode) onToggleSurveyMode()}}>
              {" "}
              {`${String.fromCharCode(60)} Zum Fragebogen ${String.fromCharCode(
                60
              )}`}
            </Button>
          </Box>
        )}
      </Grid>
    </div>
  );
}
