import { _approaches, _subjects } from "../../data/QuestionsData";

export function prepareMatches(fieldScoresSorted) {
  if (!fieldScoresSorted) {
    return [];
  }
  //fieldscores are sorted high>low
  const matchData = fieldScoresSorted.map((field) => {
    const fieldsFound = _approaches.filter((a) => a.field === field.name);

    if (!fieldsFound || fieldsFound.length === 0) {
      return null;
    }
    const fieldFound = fieldsFound[0];
    return fieldFound;
  });
  return matchData.filter((data) => data != null).slice(0, 3);
}

export function sortAndMatchCoachACFs(_coach) {
  if (typeof _coach === "undefined" || _coach === null) {
    return null;
  }

  const sortAndMatchAcfs = (definitions, coach) => {
    if (!coach || !coach.acf || Object.keys(coach.acf).length === 0) {
      return {};
    } else {
      // get all coachfinder_subject_xxx entries
      // and create a object for that with the coaches field-value
      const acfObjSorted = definitions
        .map((def) => {
          const field = coach.acf[def.field];

          if (typeof field === "undefined" || field === null) {
            // eslint-disable-next-line
            return;
          }
          // eslint-disable-next-line
          const newField = { ...def, value: parseInt(coach.acf[def.field]) };
          return newField;
        })
        .filter((newField) => typeof newField !== "undefined")
        .sort((a, b) => (a.value > b.value ? -1 : 1));

      return acfObjSorted;
    }
  };

  const coachesWithsortedAcfs = [_coach]
    .map((c) => {
      return {
        ...c,
        coachfinder_approach_sorted: sortAndMatchAcfs(_approaches, c),
      };
    })
    .map((c) => {
      return {
        ...c,
        coachfinder_subject_sorted: sortAndMatchAcfs(_subjects, c),
      };
    });

  return coachesWithsortedAcfs[0];
}
