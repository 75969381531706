module.exports = {
  login: 'Login',
  sign_up: 'Sign up',
  header_home: 'Header Home Englisht',
  header_login: 'The coach in your pocket.',
  forgot_password: 'Forgot password',
  reset_password: 'Reset password',
  username_email: 'Username/Email',
  new_mentor_app: 'New to Mentor app?',
  Email: 'Email',
  fullname: 'Full Name',
  username: 'Username',
  password: 'Password',
  birthday: 'Birthday',
  meetings: 'Meetings',
  dashboard: 'Dashboard',
  my_coach: 'My Coach',
  coache: 'Coachees',
  historie: 'Historie',
  toolbox: 'Toolbox',
  organisation: 'Organisation',
  journal: 'Journal',
  activities: 'Activities',
  loading: 'Loading',
  my_profile: 'My Profile',
  settings: 'Settings',
  log_out: 'Log out',
  welcome: 'Welcome',
  start_session: 'Start session',
};