import Api from "../network/Api";

import { GET_COACHES_PATH } from "../network/ApiConstants";
import { subjects } from "../data/CoachSubjectData";

async function getCoaches(coachesCallback) {
  Api.get(
    GET_COACHES_PATH,
    (data) => {
      //here if we need we can parse or change the data
      //particulary for coaches
      //console.log(data);

      // coachesCallback(parseData(data));
      coachesCallback(data);
    },
    (error) => {
      //handle error
      console.warn("Error: " + error);
      coachesCallback(null);
    }
  );
}

function getHighestCoachSubjectName(coach) {
  const coachData = coach.acf;
  var subjectsData = {};

  //first we find the keys of the objects that we need to sort
  const subjectKeys = Object.keys(coachData).filter((k) =>
    k.startsWith("coachfinder_subject")
  );

  //using these keys we create a new array with these object and values
  subjectKeys.map((key) => (subjectsData[key] = coachData[key]));

  //find the heighest value from these objects
  //and return its key
  if (!isEmpty(subjectsData)) {
    const keyOfMaxValue = Object.keys(subjectsData).reduce((a, b) =>
      parseInt(subjectsData[a], 10) > parseInt(subjectsData[b], 10) ? a : b
    );

    //filter local data using the max value key that we found
    const filteredSubject = subjects.filter(
      (subject) => subject.sortField === keyOfMaxValue
    );
    return filteredSubject[0].name;
  }
  return null;
}

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

const CoachService = {
  getCoaches,
  getHighestCoachSubjectName,
};

export default CoachService;
