export const iconsApproachYellow = importAll(
  require.context(
    "../assets/icons/Icons_Arbeitsgebiete/Arbeitsgebiete_yellow/.",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
export const iconsSubjectYellow = importAll(
  require.context(
    "../assets/icons/Icons_Themen/Themen_yellow/.",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

export const iconsApproachWhite = importAll(
  require.context(
    "../assets/icons/Icons_Arbeitsgebiete/Arbeitsgebiete_white/.",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
export const iconsSubjectWhite = importAll(
  require.context(
    "../assets/icons/Icons_Themen/Themen_white/.",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

function importAll(r) {
  //console.log("r", r)
  return r.keys().map(r);
}