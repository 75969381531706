import React, { useContext } from "react";

// import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import useMediaQueryMui from "@material-ui/core/useMediaQuery";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Typography, Hidden } from "@material-ui/core";
import { SurveyContext } from "../../store/contexts/Contexts";
import bubbles1 from "../../assets/images/Gruppe 708.png";
import bubbles2 from "../../assets/images/Gruppe 709.png";

import hanna from "../../assets/images/HANNA_Mentor-183.png";
import speakbubble from "../../assets/images/Desktop_Sprechblase.png";
import speakbubbleMob from "../../assets/images/Mobil_Sprechblase.png";


const useStyles = makeStyles((theme) => ({
  root: {},
  mainGrid: {
    width: "100%",
    height: "70vh",
    maxWidth: 400,
  },
  title: {
    textAlign: "left",
    fontSize: "1.5rem",
    fontWeight: 800,
    color: "black",
    paddingBottom: "3vh",
  },
  titleMob: {
    textAlign: "left",
    fontSize: "1.4rem",
    fontWeight: 800,
    color: "black",
    paddingBottom: "10px",
    lineHeight: "1.2",
  },
  titleHighlight: {
    fontSize: "1.5rem",
    fontWeight: 800,
    color: "primary",
  },
  titleHighlightMob: {
    fontSize: "1.4rem",
    fontWeight: 800,
    color: "primary",
    lineHeight: "1.2",
  },
  introText: {
    textAlign: "left",
    fontSize: "1.1rem",
    fontWeight: 500,
    paddingBottom: "7vh",
  },
  introTextMob: {
    textAlign: "left",
    fontSize: "1.0rem",
    fontWeight: 500,
    paddingBottom: "7vh",
    lineHeight: "1.2",
  },
  introTextHighlight: {
    fontWeight: 800,
  },
  introTextHighlightMob: {
    fontWeight: 800,
  },
  buttonBox: {
    height: "12vh",
  },
  button: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    border: "1px solid white",
    fontSize: "1.1rem",
    fontWeight: 800,
    maxWidth: "45vw",
    borderRadius: 20,
    textTransform: "none",
  },
  description: {
    height: "10vh",
    textAlign: "center",
    padding: "1vw",
    fontSize: "0.9rem",
    color: theme.palette.grey.main,
  },
  bottomText: {
    textAlign: "center",
    padding: "2vw",
    fontSize: "0.9rem",
    fontWeight: 800,
    color: theme.palette.grey.main,
  },
}));

export default function FinderIntro({onStart}) {
  const { setCurrentQuestion } = useContext(SurveyContext);

  const classes = useStyles();
  // const { t } = useTranslation();
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 768px)",
  });

  

  //const theme = useTheme();

  //console.log(theme.breakpoints.values);
  const xsDown = useMediaQueryMui((theme) => theme.breakpoints.down("xs"));
  const smUp = useMediaQueryMui((theme) => theme.breakpoints.up("sm"));
  const smDown = useMediaQueryMui((theme) => theme.breakpoints.down("sm"));
  const lgUp = useMediaQueryMui((theme) => theme.breakpoints.up("lg"));
  const lgDown = useMediaQueryMui((theme) => theme.breakpoints.down("lg"));
  const mdUp = useMediaQueryMui((theme) => theme.breakpoints.up("md"));
  const mdDown = useMediaQueryMui((theme) => theme.breakpoints.down("md"));

  const containerLeft = () => {
    if (xsDown) return "-180px";
    if (smDown) return "-70px";
    if (mdDown) return "-130px";
    if (lgDown) return "0px";

    return "-115px";
  };

  return (
    <div
      style={{
        backgroundImage: `url(${bubbles1})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50vw 30vh",
        position: "relative",
        //backgroundSize: "contain",
        textAlign: "-webkit-center",
        marginTop: `${isTabletOrMobileDevice ? "0" : "10vh"}`,
      }}
    >
      <Box
        display="flex"
        style={{
          overflowY: "hidden",
          height: "calc(100vh - 100px)",
        }}
      >
        <Box
          display="flex"
          style={{
            position: "absolute",
            left: containerLeft(),
            width: "1200px",
            overflowY: "hidden",
            height: "calc(100vh - 100px)",
          }}
        >
          <img
            src={bubbles2}
            style={{
              position: "absolute",
              top: "30vh",
              left: "50vw",
              zIndex: -1,
            }}
            alt=""
          />
          <img
            src={bubbles1}
            style={{
              position: "absolute",
              top: "10vh",
              left: "26vw",
              zIndex: -1,
              transform: "rotate(200deg)",
            }}
            alt=""
          />
          {/* {"mdDown" + mdDown + "  mdUp" + mdUp + "  smDown" +smDown +"  smUp" + smUp + " lgDown" + lgDown + " lgUp" + lgUp} */}
          <img
            src={hanna}
            style={{
              height: mdUp
                ? "1300px"
                : xsDown
                ? "800px"
                : smDown
                ? "1000px"
                : "",
            }}
            alt=""
          />
          <Box
            display="flex"
            flexDirection="column"
            style={{
              position: "absolute",
              left: lgUp ? "500px" : xsDown ? "65vw" : "45vw",
            }}
          >
            <Hidden smDown>
              <img src={speakbubble} style={{ height: "300px" }} alt="" />
            </Hidden>
            <Hidden mdUp>
              <img
                src={speakbubbleMob}
                style={{ height: smUp ? "500px" : "410px" }}
                alt=""
              />
            </Hidden>
            <Box
              display="flex"
              style={{
                position: "absolute",
                top: 25,
                left: 70,
                height: "auto",
                width: "auto",
                marginRight: "30px",
                flexDirection: "column",
              }}
            >
              <Typography
                className={smUp ? classes.title : classes.titleMob}
                color="primary"
              >
                Mit den nächsten{" "}
                <Typography
                  className={
                    smUp ? classes.titleHighlight : classes.titleHighlightMob
                  }
                  color="primary"
                  component="span"
                >
                  12 Fragen
                </Typography>{" "}
                finden wir den Coach für dich, der zu dir passt!
              </Typography>
              <Typography
                className={smUp ? classes.introText : classes.introTextMob}
                color="black"
              >
                <Typography
                  className={
                    smUp
                      ? classes.introTextHighlight
                      : classes.introTextHighlightMob
                  }
                  color="black"
                  component="span"
                >
                  "Hi, ich bin Hanna.{" "}
                </Typography>
                <br />
                Ich bin eine der
                <Typography
                  className={
                    smUp
                      ? classes.introTextHighlight
                      : classes.introTextHighlightMob
                  }
                  color="black"
                  component="span"
                >
                  {" "}
                  Coaches bei mentor.{" "}
                </Typography>
                Die folgenden Fragen helfen uns, die Coaches für dich zu
                priorisieren, so dass du ganz schnell den Coach findest, der zu
                dir passt. Also lehn dich zurück,
                <Typography
                  className={
                    smUp
                      ? classes.introTextHighlight
                      : classes.introTextHighlightMob
                  }
                  color="black"
                  component="span"
                >
                  {" "}
                  nimm dir kurz Zeit...{" "}
                </Typography>
                und
                <Typography
                  className={
                    smUp
                      ? classes.introTextHighlight
                      : classes.introTextHighlightMob
                  }
                  color="black"
                  component="span"
                >
                  {" "}
                  los gehts!"
                </Typography>
              </Typography>
            </Box>
            <Box
              className={classes.buttonBox}
              width="100%"
              justifyContent="center"
              style={{
                marginLeft: 0,
                marginTop: 0,
              }}
            >
              <Button
                className={classes.button}
                style={{
                  marginTop: mdUp ? "70px" : "10px",
                  minWidth: "280px",
                  // marginLeft: "30px",
                }}
                variant="contained"
                color="primary"
                onClick={() => {
                  setCurrentQuestion(0);
                  if (onStart) onStart();
                }}
              >
                Jetzt meinen Coach finden
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Grid container justify="center" className={classes.mainGrid}>
        <Grid item>
          <Typography className={classes.title} color="primary">
            Finde deinen Coach
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            className={classes.introText}
            style={{
              paddingLeft: `${isTabletOrMobileDevice ? "12vw" : "0"}`,
              paddingRight: `${isTabletOrMobileDevice ? "12vw" : "0"}`,
            }}
          >
            Mit deinen Antworten auf die nächsten Fragen findest du den Coach,
            der zu dir und deinem Anliegen passt.
          </Typography>
        </Grid>

        <Grid item>
          <Box className={classes.buttonBox}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => {
                setCurrentQuestion(0);
              }}
            >
              Jetzt meinen Coach finden
            </Button>
          </Box>
        </Grid>
        <Grid item>
          <Typography className={classes.description}>
            Du kannst deine Eingabe jederzeit ändern, indem du einfach von unten
            nach oben über den Bildschirm wischst.
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.bottomText}>
            <Button href="/coaches" >
             Zurück zur Coachübersicht
             </Button>
          </Typography>
        </Grid>
      </Grid> */}
    </div>
  );
}
