import axios from "axios";
import { BASE_URL } from "./ApiConstants";

function get(path, dataCallback, errorCallback, parameters = null) {
  const url = BASE_URL + path;

  //console.log("api - get", path, dataCallback, errorCallback, parameters);
  try {
    axios
      .get(url, parameters && { params: parameters })
      .then((response) => {
        if (dataCallback != null && response.data != null) {
          dataCallback(response.data);
        }
      })
      .catch(function (error) {
        console.warn("error---------");
        console.warn(error);
        if (errorCallback != null) {
          errorCallback(error);
        }
      });
  } catch (error) {
    console.warn(error);
    if (errorCallback != null) {
      errorCallback(error);
    }
    throw error;
  }
}

function post(path, data, dataCallback, errorCallback) {
  const url = BASE_URL + path;
  try {
    axios
      .post(url, data)
      .then((response) => {
        console.log("response");
        console.log(response);
        if (dataCallback != null && response.data != null) {
          dataCallback(response.data);
        }
      })
      .catch(function (error) {
        console.warn("error---------");
        console.warn(error);
        console.log("response error post");
        console.log(error.response);

        dataCallback(null);
      });
  } catch (error) {
    console.warn(error);
    if (errorCallback != null) {
      errorCallback(error);
    }
    throw error;
  }
}

function put(path, data, dataCallback, errorCallback) {
  const url = BASE_URL + path;
  try {
    axios
      .put(url, data)
      .then((response) => {
        console.log("response");
        console.log(response);
        if (dataCallback != null && response.data != null) {
          dataCallback(response.data);
        }
      })
      .catch(function (error) {
        console.warn("error---------");
        console.warn(error);
        console.log("response error put");
        console.log(error.response);

        dataCallback(null);
      });
  } catch (error) {
    console.warn(error);
    if (errorCallback != null) {
      errorCallback(error);
    }
    throw error;
  }
}



const Api = {
  get,
  post,
  put,
};
export default Api;
